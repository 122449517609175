export const UPDATE_CURRENT = "UPDATE_CURRENT";
export const UPDATE_CURRENT_ACCOUNT = "UPDATE_CURRENT_ACCOUNT";
export const UPDATE_CURRENT_ORGANIZATION = "UPDATE_CURRENT_ORGANIZATION";
export const UPDATE_CURRENT_PROFILE = "UPDATE_CURRENT_PROFILE";
export const UPDATE_CURRENT_RECOMMENDATION = "UPDATE_CURRENT_RECOMMENDATION";
export const UPDATE_CURRENT_REPORT = "UPDATE_CURRENT_REPORT";
export const UPDATE_CURRENT_USER = "UPDATE_CURRENT_USER";

export const updateCurrent = (current) => ({type: UPDATE_CURRENT, current});
export const updateCurrentAccount = (account) => ({type: UPDATE_CURRENT_ACCOUNT, account});
export const updateCurrentOrganization = (organization) => ({
  type: UPDATE_CURRENT_ORGANIZATION, organization
});
export const updateCurrentProfile = (profile) => ({type: UPDATE_CURRENT_PROFILE, profile});
export const updateCurrentRecommendation = (recommendation) => ({
  type: UPDATE_CURRENT_RECOMMENDATION, recommendation
});
export const updateCurrentReport = (report) => ({type: UPDATE_CURRENT_REPORT, report});
export const updateCurrentUser = (user) => ({type: UPDATE_CURRENT_USER, user});

export const displayNameFrom = (object) => {
  if(!object) { return undefined; }
  if(object.name) { return object.name; }

  const name = [object.first_name, object.last_name].filter(Boolean).join(" ");
  return name || object.external_id || object.email;
};

export const displayName = ({account, profile}) => (
  displayNameFrom(profile) || displayNameFrom(account)
);

export const displayInitialsFrom = (object) => {
  const fullName = displayNameFrom(object);
  if(!fullName) { return undefined; }

  return fullName.split(" ").map((name) => name[0]).join("").toUpperCase();
};

export const displayInitials = ({account, profile}) => (
  displayInitialsFrom(profile) || displayInitialsFrom(account)
);
