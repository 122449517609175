export const LOAD_ASSESSMENTS = "LOAD_ASSESSMENTS";
export const REQUEST_ASSESSMENTS = "REQUEST_ASSESSMENTS";
export const REQUEST_ASSESSMENTS_FAILED = "REQUEST_ASSESSMENTS_FAILED";
export const REQUEST_ASSESSMENTS_FULFILLED = "REQUEST_ASSESSMENTS_FULFILLED";
export const REQUEST_ASSESSMENTS_REJECTED = "REQUEST_ASSESSMENTS_REJECTED";
export const REQUEST_ASSESSMENTS_SUCCEEDED = "REQUEST_ASSESSMENTS_SUCCEEDED";
export const STORE_ASSESSMENTS = "STORE_ASSESSMENTS";

export const loadAssessments = () => ({type: LOAD_ASSESSMENTS});
export const requestAssessments = () => ({type: REQUEST_ASSESSMENTS});
export const requestAssessmentsFailed = (error) => ({type: REQUEST_ASSESSMENTS_FAILED, error});
export const requestAssessmentsFulfilled = (data) => ({
  type: REQUEST_ASSESSMENTS_FULFILLED, data
});
export const requestAssessmentsRejected = (error) => ({type: REQUEST_ASSESSMENTS_REJECTED, error});
export const requestAssessmentsSucceeded = (assessments) => ({
  type: REQUEST_ASSESSMENTS_SUCCEEDED, assessments
});
export const storeAssessments = (assessments) => ({type: STORE_ASSESSMENTS, assessments});
