import {connect} from "react-redux";
import {addDependencies} from "legacy/constants/app";
import Component from "./presenter";

const mapStateToProps = ({app}) => ({app});

export default connect(
  mapStateToProps,
  {addDependencies}
)(Component);
