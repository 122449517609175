import {useMemo} from "react";
import useQueryParams from "common/hooks/use-query-params";
import {toQueryString} from "common/lib/ext/object";
import Survey from "legacy/components/partials/assessments/survey";
import useDidMount from "legacy/lib/hooks/use-did-mount";
import usePageClass from "legacy/lib/hooks/use-page-class";

export default function RecommendationSurvey() {
  const queryParams = useQueryParams();
  const benchmarkID = queryParams.get("benchmark_id");
  const packageID = queryParams.get("package_id");
  const redirect = useMemo(() => {
    const query = {};
    if(benchmarkID) { query.benchmark_id = benchmarkID; }
    if(packageID) { query.package_id = packageID; }
    const queryString = toQueryString(query);

    return `/recommendation/complete?${queryString}`;
  }, [benchmarkID, packageID]);

  useDidMount(() => {
    window.google_conversion_id = 965414859;
    window.google_conversion_language = "en";
    window.google_conversion_format = "3";
    window.google_conversion_color = "ffffff";
    window.google_conversion_label = "TnCkCPuw0WMQy5-szAM";
    window.google_remarketing_only = false;
  });
  usePageClass("survey");

  return (
    <>
      <Survey benchmarkID={benchmarkID} packageID={packageID} redirect={redirect} />
      <script src="//www.googleadservices.com/pagead/conversion.js" type="text/javascript" />
    </>
  );
}
