import PropTypes from "prop-types";
import {Component} from "react";
import I18n from "common/lib/i18n";
import {Modal} from "legacy/components/partials";
import Axios, {i18nAxios} from "legacy/lib/axios";

export default class ExportModal extends Component {
  static propTypes = {
    account: PropTypes.shape({
      email: PropTypes.string
    }).isRequired,
    closeModal: PropTypes.func.isRequired,
    createNotification: PropTypes.func.isRequired,
    i18n: PropTypes.instanceOf(I18n).isRequired,
    locale: PropTypes.shape({
      code: PropTypes.string.isRequired
    }).isRequired
  };
  constructor(props) {
    super(props);
    this.state = {email: props.account.email || ""};
  }
  componentWillUnmount() {
    const {request} = this.state;
    request && request.cancel();
  }
  onEmailChange = ({target: {value}}) => {
    this.setState({email: value});
  };
  onSubmit = (e) => {
    e.preventDefault();

    const {closeModal, createNotification, locale} = this.props;
    const axios = i18nAxios(locale.code);
    const params = {email: this.state.email};
    const request = Axios.CancelToken.source();

    axios.post("/api/user/user/export", params, {
      cancelToken: request.token
    }).then(({data: {errors, message, success}}) => {
      if(success) {
        this.setState({errors: null, request: null}, () => {
          createNotification({kind: "success", message});
          closeModal();
        });
      } else {
        this.setState({errors, request: null});
      }
    });

    this.setState({request});
  };
  render() {
    const {closeModal, i18n} = this.props;
    const {errors, email} = this.state;

    return (
      <Modal className="p-modal" onClose={closeModal} paradox={true} show={true} title={i18n.t("settings.export_heading")}>
        <form className="p-form-default" onSubmit={this.onSubmit}>
          <ul>
            <section className="modal__inputs">
              <li className="form__disclaimer text-left">
                <p>{i18n.t("settings.export_text")}</p>
              </li>
              <li>
                <label className="label" htmlFor="email">{i18n.t("attributes.email")}</label>
                <input className="p-form-field-lg" name="email" onChange={this.onEmailChange} placeholder={i18n.t("attributes.example_email")} type="text" value={email} />
              </li>
              {errors && <li className="error">{errors}</li>}
            </section>
            <section className="modal__options">
              <button className="m-buffer-sm p-btn-blue" type="submit">{i18n.t("submit")}</button>
            </section>
          </ul>
        </form>
      </Modal>
    );
  }
}
