import {connect} from "react-redux";
import {loadAssessment} from "legacy/constants/assessment";
import Component from "./presenter";

const mapStateToProps = ({
  assessment,
  assessments,
  current: {organization},
  locale
}) => ({
  assessment,
  assessments,
  locale,
  organization
});

export default connect(
  mapStateToProps,
  {loadAssessment}
)(Component);
