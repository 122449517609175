export const VERIFY_CODE = "SESSIONS_VERIFY_CODE";
export const VERIFY_CODE_FAILED = "SESSIONS_VERIFY_CODE_FAILED";
export const VERIFY_CODE_FULFILLED = "SESSIONS_VERIFY_CODE_FULFILLED";
export const VERIFY_CODE_REJECTED = "SESSIONS_VERIFY_CODE_REJECTED";
export const VERIFY_CODE_SUCCEEDED = "SESSIONS_VERIFY_CODE_SUCCEEDED";

export const verifyCode = (code) => ({type: VERIFY_CODE, code});
export const verifyCodeFailed = (error) => ({type: VERIFY_CODE_FAILED, error});
export const verifyCodeFulfilled = (response) => ({type: VERIFY_CODE_FULFILLED, response});
export const verifyCodeRejected = (error) => ({type: VERIFY_CODE_REJECTED, error});
export const verifyCodeSucceeded = (data) => ({type: VERIFY_CODE_SUCCEEDED, data});
