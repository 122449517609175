import BaseMarkdown from "markdown-to-jsx";

export default function Markdown(props) {
  const options = {
    overrides: {
      a: {
        component: "a",
        props: {rel: "noopener noreferrer", target: "_blank"}
      }
    }
  };

  return <BaseMarkdown options={options} {...props} />;
}
