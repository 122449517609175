import {connect} from "react-redux";
import {updateLocale} from "legacy/constants/locale";
import Component from "./presenter";

const mapStateToProps = ({locale}) => ({locale});

export default connect(
  mapStateToProps,
  {updateLocale}
)(Component);
