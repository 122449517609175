import Rails from "@rails/ujs";
import Axios from "axios";

const addToken = (_config) => {
  const config = {..._config};
  config.headers["X-CSRF-Token"] = Rails.csrfToken();
  return config;
};

Axios.defaults.headers.common.Accept = "application/json";
Axios.interceptors.request.use(addToken);

export const i18nAxios = (locale) => {
  const instance = Axios.create();
  instance.defaults.headers.common.Accept = "application/json";
  instance.defaults.headers.common["Override-Language"] = locale;
  instance.interceptors.request.use(addToken);
  return instance;
};

export default Axios;
