import {connect} from "react-redux";
import I18n from "common/lib/i18n";
import {Loader} from "legacy/components/partials";
import {shareAssessment} from "legacy/constants/assessment";
import Component from "./presenter";

const mapStateToProps = ({
  assessment, current: {organization}, locale
}) => ({
  assessment, i18n: new I18n(locale, organization), organization
});

const ConnectedComponent = connect(
  mapStateToProps,
  {shareAssessment}
)(Component);

export default () => (
  <Loader component={ConnectedComponent} dependencies={["assessment"]} />
);
