import PropTypes from "prop-types";
import {useParams} from "react-router-dom";
import I18n from "common/lib/i18n";
import useMine from "legacy/lib/hooks/assessment/use-mine";
import useSurveyName from "legacy/lib/hooks/assessment/use-survey-name";

function Heading({assessment, i18n, organization, shareAssessment}) {
  const {adminToken} = useParams();
  const mine = useMine();
  const surveyName = useSurveyName();

  const body = i18n.t("results.email_body", {
    personality_url: `${organization.url}/personality/${assessment.id}`,
    session_url: `${organization.url}/sessions/new`
  }).replace(/ /g, "%20");
  const printUrl = adminToken
    ? `/personality/${assessment.id}.pdf?admin_token=${adminToken}`
    : `/personality/${assessment.id}.pdf`;
  const subject = `Traitify ${i18n.t("results.personality_results")}`.replace(/ /g, "%20");

  return (
    <section className="md-flex md-items-center md-justify-between p-box mb-section mt-buffer">
      <div className="md-mb-0 mb-buffer">
        <div className="p-heading">{surveyName} {i18n.t("results.assessment_results")}</div>
      </div>
      <div className="actions">
        {(mine && organization.options.allow_retake) && (
          <a href={`/assessment?r=etry&deck_id=${assessment.deck_id}`} title={i18n.t("results.retake_assessment")}>
            <i alt={i18n.t("results.retake_assessment")} className="fa fa-undo fa-flip-horizontal" />
          </a>
        )}
        {assessment.deck_id.includes("career") && (
          <a href="https://cdn.traitify.com/creative/downloads/Traitify_SelfDiscoveryGuide.pdf" rel="noopener noreferrer" target="_blank" title="Self Discovery Guide">
            <i alt="Self Discovery Guide" className="fa fa-compass" />
          </a>
        )}
        <a href={printUrl} rel="noopener noreferrer" target="_blank" title={i18n.t("results.print")}>
          <i alt={i18n.t("results.print")} className="fa fa-print" />
        </a>
        {mine && (
          <a href={`mailto:?subject=${subject}&body=${body}`} rel="noopener noreferrer" target="_blank" title={i18n.t("results.email")}>
            <i alt={i18n.t("results.email")} className="fa fa-envelope-o" />
          </a>
        )}
        {organization.options.enable_sharing && (
          <div className="share-dropdown">
            <span>
              <i className="fa fa-share-square" />
            </span>
            <div>
              <button title={i18n.t("results.share")} onClick={() => shareAssessment("facebook")} type="button">
                <i alt={i18n.t("results.share")} className="fa fa-facebook" />
              </button>
              <button title={i18n.t("results.twitter")} onClick={() => shareAssessment("twitter")} type="button">
                <i alt={i18n.t("results.twitter")} className="fa fa-twitter" />
              </button>
              <button title={i18n.t("results.linkedin")} onClick={() => shareAssessment("linkedin")} type="button">
                <i alt={i18n.t("results.linkedin")} className="fa fa-linkedin" />
              </button>
            </div>
          </div>
        )}
        {organization.contact_emails && organization.contact_emails.length > 0 && (
          <a href={`mailto:${organization.contact_emails}`} rel="noopener noreferrer" target="_blank" title={i18n.t("results.contact_us")}>
            <i alt={i18n.t("results.contact_us")} className="fa fa-question-circle" />
          </a>
        )}
        {(mine && window !== window.top) && (
          <a href="/sessions" data-method="delete" title={i18n.t("results.logout")}>
            <i alt={i18n.t("results.logout")} className="fa fa-power-off" />
          </a>
        )}
      </div>
    </section>
  );
}

Heading.propTypes = {
  assessment: PropTypes.shape({
    deck_id: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired
  }).isRequired,
  i18n: PropTypes.instanceOf(I18n).isRequired,
  organization: PropTypes.shape({
    contact_emails: PropTypes.string.isRequired,
    options: PropTypes.shape({
      allow_retake: PropTypes.bool.isRequired,
      enable_sharing: PropTypes.bool.isRequired
    }).isRequired,
    url: PropTypes.string.isRequired
  }).isRequired,
  shareAssessment: PropTypes.func.isRequired
};

export default Heading;
