import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import I18n from "common/lib/i18n";
import Field from "legacy/components/partials/form/field";
import traitify from "legacy/lib/traitify";

function PackageField({i18n, organization, setValue, value}) {
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    traitify.http.get(`/packages?organization_uuid=${organization.uuid}`).then((data) => {
      setPackages(data);
      setValue(null);
    }).catch(() => setValue("skip"));
  }, [organization]);

  if(packages.length === 0 || value === "skip") { return null; }

  const onChange = (e) => setValue(e.target.value);

  return (
    <li>
      <Field label={i18n.tOption("package_label")} name="package_id" onChange={onChange} type="select" value={value || ""}>
        {packages.map(({id, name}) => (
          <option key={id} value={id}>{name}</option>
        ))}
      </Field>
    </li>
  );
}

PackageField.defaultProps = {value: null};
PackageField.propTypes = {
  i18n: PropTypes.instanceOf(I18n).isRequired,
  organization: PropTypes.shape({uuid: PropTypes.string.isRequired}).isRequired,
  setValue: PropTypes.func.isRequired,
  value: PropTypes.string
};

export default PackageField;
