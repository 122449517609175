import {combineEpics, ofType} from "redux-observable";
import {map, mergeMap, filter} from "rxjs/operators";
import {
  ADD_DEPENDENCIES,
  DEPENDENCY_ERROR,
  LOAD_DEPENDENCIES,
  loadApp,
  loadDependency
} from "legacy/constants/app";
import {createNotification} from "legacy/constants/notifications";

const addDependenciesEpic = (action$, state$) => action$.pipe(
  ofType(ADD_DEPENDENCIES),
  filter(() => !state$.value.app.loading),
  map(loadApp)
);

const loadDependenciesEpic = (action$, state$) => action$.pipe(
  ofType(LOAD_DEPENDENCIES),
  mergeMap(() => state$.value.app.dependencies.load.map(loadDependency))
);

const dependencyErrorEpic = (action$) => action$.pipe(
  ofType(DEPENDENCY_ERROR),
  map(({error}) => ({kind: "error", message: error})),
  map(createNotification)
);

export default combineEpics(
  addDependenciesEpic,
  dependencyErrorEpic,
  loadDependenciesEpic
);
