import {useSelector} from "react-redux";

export default function useMine() {
  const assessment = useSelector((state) => state.assessment);
  const user = useSelector((state) => state.current.user);

  if(!assessment) { return false; }
  if(!user) { return false; }

  return assessment.profile_ids.some((id) => id === user.profile_uuid);
}
