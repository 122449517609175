import {STORE_GEOLOCATION} from "legacy/constants/geolocation";

export default (
  state = null,
  action
) => {
  switch(action.type) {
    case STORE_GEOLOCATION:
      return action.geolocation;
    default:
      return state;
  }
};
