import PropTypes from "prop-types";
import {Outlet} from "react-router-dom";
import useScrollbarWidth from "common/hooks/use-scrollbar-width";
import {Layout, Pages} from "legacy/components";
import {Loader} from "legacy/components/partials";

function Container({loading, session: {iframe}}) {
  useScrollbarWidth();

  return (
    <div className="flex flex-col h-screen">
      {!iframe && <Loader component={Layout.Navbar} dependencies={["assessments"]} />}
      <Loader component={Layout.OriginalAccount} />
      <Loader component={Layout.Notifications} />
      <main className="flex-auto main" id="main" role="main" tabIndex="-1">
        <div className="wrapper">
          <div className="row">
            {loading ? <Pages.Loading /> : <Outlet />}
          </div>
        </div>
      </main>
      {!iframe && <Loader component={Layout.Footer} />}
    </div>
  );
}

Container.propTypes = {
  loading: PropTypes.bool.isRequired,
  session: PropTypes.shape({iframe: PropTypes.bool}).isRequired
};

export default Container;
