import PropTypes from "prop-types";
import {useState} from "react";
import I18n from "common/lib/i18n";
import * as Account from "legacy/components/partials/account";
import DeleteModal from "./delete-modal";
import ExportModal from "./export-modal";

function Settings({current, i18n}) {
  const [organization, setOrganization] = useState(current.organization);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const onOrganizationChange = ({target: {value}}) => {
    const user = current.account.users.find(({organization: {uuid}}) => uuid === value);

    setOrganization(user.organization);
  };

  return (
    <div className="max-w-screen-xs mx-auto p-shadow-box">
      <h3 className="mt-0">{i18n.t("settings.account")}</h3>
      <Account.Form />
      <div className="border-b-none border-t-md border-solid border-theme" />
      <h3>{i18n.t("settings.other")}</h3>
      <div>
        <button className="p-btn-outline-theme mr-buffer" onClick={() => setShowExportModal(true)} type="button">{i18n.t("settings.export_profile")}</button>
        <button className="p-btn-red" onClick={() => setShowDeleteModal(true)} type="button">{i18n.t("settings.delete_profile")}</button>
      </div>
      {current.account.users.length > 1 && (
        <div className="settings-organization">
          <p>{i18n.t("settings.other_organization")}</p>
          <select className="p-form-field" name="organization" onChange={onOrganizationChange} value={organization.uuid}>
            {current.account.users.map(({organization: {name, uuid}}) => (
              <option key={uuid} value={uuid}>{name}</option>
            ))}
          </select>
          <p>
            <a href={`${organization.url}/settings`} className={current.organization.uuid === organization.uuid ? "p-btn-gray p-btn-inactive " : "p-btn-gray"}>
              {i18n.t("view")}
            </a>
          </p>
        </div>
      )}
      {showDeleteModal && <DeleteModal closeModal={() => setShowDeleteModal(false)} />}
      {showExportModal && <ExportModal closeModal={() => setShowExportModal(false)} />}
    </div>
  );
}

Settings.propTypes = {
  current: PropTypes.shape({
    account: PropTypes.shape({
      users: PropTypes.arrayOf(
        PropTypes.shape({
          organization: PropTypes.shape({
            name: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
            uuid: PropTypes.string.isRequired
          }).isRequired
        }).isRequired
      ).isRequired
    }).isRequired,
    organization: PropTypes.shape({
      url: PropTypes.string.isRequired,
      uuid: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  i18n: PropTypes.instanceOf(I18n).isRequired
};

export default Settings;
