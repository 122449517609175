/* eslint-disable camelcase */
import PropTypes from "prop-types";
import {useState} from "react";
import I18n from "common/lib/i18n";
import Stripe from "legacy/components/partials/assessments/stripe";
import useMine from "legacy/lib/hooks/assessment/use-mine";
import useCognitiveTest from "legacy/lib/hooks/use-cognitive-test";
import AssessmentType from "./assessment-type";

function Picker({assessment, assignLocation, current, i18n, locale, loadAssessment}) {
  const [show, setShow] = useState(false);
  const cognitiveTest = useCognitiveTest({locale, organization: current.organization, assessment});
  const mine = useMine();
  const assessmentTypesAll = current.organization.assessment_types;
  const cognitiveAssessmentType = assessmentTypesAll.find(({deck_type}) => deck_type === "COGNITIVE");
  const assessmentTypes = assessmentTypesAll.filter(({deck_type}) => deck_type !== "COGNITIVE");
  const setAssessment = (assessmentID) => {
    loadAssessment(assessmentID);
    assignLocation(`/personality/${assessmentID}`);
    setShow(false);
  };
  if(!current.organization.options.show_results) { return null; }
  if(assessmentTypesAll.length <= 1 || !mine) { return <div />; }

  let content;
  let icon;
  let text;
  if(show) {
    icon = <i className="fa fa-angle-up" />;
    text = i18n.t("results.hide_other_assessments");

    content = (
      <div className="picker__assessments" role="tabpanel">
        <div className="picker__available">
          <ul>
            {cognitiveTest && (
              <AssessmentType
                key={cognitiveTest.surveyID}
                cognitiveTest={cognitiveTest}
                assessmentType={cognitiveAssessmentType}
                setAssessment={setAssessment}
              />
            )}
            {assessmentTypes.map((assessmentType) => (
              <AssessmentType
                key={assessmentType.deck_id}
                assessmentType={assessmentType}
                setAssessment={setAssessment}
              />
            ))}
          </ul>
        </div>
      </div>
    );
  } else {
    icon = <i className="fa fa-angle-down" />;
    text = i18n.t("results.view_other_assessments");
  }

  return (
    <div className="picker">
      <Stripe>
        {content}
        <button onClick={() => setShow(!show)} type="button">{icon} {text} {icon}</button>
      </Stripe>
    </div>
  );
}

Picker.defaultProps = {locale: null};
Picker.propTypes = {
  assessment: PropTypes.shape({
    deck_id: PropTypes.string,
    id: PropTypes.string.isRequired
  }).isRequired,
  assignLocation: PropTypes.func.isRequired,
  current: PropTypes.shape({
    organization: PropTypes.shape({
      assessment_types: PropTypes.arrayOf(
        PropTypes.shape({
          deck_id: PropTypes.string.isRequired
        }).isRequired
      ).isRequired,
      options: PropTypes.shape({
        allow_retake: PropTypes.bool.isRequired,
        show_results: PropTypes.bool.isRequired
      }).isRequired
    }).isRequired
  }).isRequired,
  i18n: PropTypes.instanceOf(I18n).isRequired,
  loadAssessment: PropTypes.func.isRequired,
  locale: PropTypes.shape({})
};

export default Picker;
