import {useSelector} from "react-redux";

export default function useDisabledComponents({admin = false} = {}) {
  const {options} = useSelector((state) => state.current.organization) || {};
  if(!options) { return []; }

  const disabledComponents = {
    Guide: !options.show_interview_guide,
    PersonalityArchetype: !options.show_big_five_archetype,
    PersonalityCaution: !options.show_big_five_caution,
    PersonalityDimensionChart: !options.show_big_five_dimension_columns,
    PersonalityDimensionDetails: !options.show_big_five_dimension_details,
    PersonalitySettings: !options.show_big_five_settings,
    PersonalitySkills: !options.show_big_five_skills,
    PersonalityTools: !options.show_big_five_tools,
    PersonalityTraits: !options.show_personality_traits,
    RecommendationList: admin ? false : !options.show_recommendation,
    RecommendationScore: !options.show_recommendation_score
  };

  return Object.keys(disabledComponents).filter((key) => disabledComponents[key]);
}
