import PropTypes from "prop-types";
import {Component} from "react";
import FieldWithoutLabel from "legacy/components/partials/form/field";
import FieldWithLabel from "legacy/components/partials/form/field-with-label";
import Axios, {i18nAxios} from "legacy/lib/axios";

export default class MajorField extends Component {
  static propTypes = {
    locale: PropTypes.shape({code: PropTypes.string.isRequired}).isRequired,
    onChange: PropTypes.func.isRequired,
    registration: PropTypes.bool.isRequired,
    value: PropTypes.number
  };
  static defaultProps = {value: null};
  constructor(props) {
    super(props);
    this.state = {majors: []};
  }
  componentDidMount() {
    this.fetchMajors();
  }
  componentWillUnmount() {
    const {request} = this.state;
    request && request.cancel();
  }
  fetchMajors = () => {
    const {locale} = this.props;
    const axios = i18nAxios(locale.code);
    const request = Axios.CancelToken.source();

    axios.get("/api/user/majors", {
      cancelToken: request.token
    }).then(({data: {majors}}) => {
      this.setState({majors, request: null});
    });

    this.setState({request});
  };
  onChange = ({target: {name, value}}) => (
    this.props.onChange({target: {name, value: value ? +value : null}})
  );
  render() {
    const {registration, value} = this.props;
    const {majors} = this.state;
    if(majors.length === 0) { return null; }

    const Field = registration ? FieldWithoutLabel : FieldWithLabel;

    return (
      <li>
        <Field name="major_id" namespace="user" onChange={this.onChange} type="select" value={value}>
          {majors.map((major) => (
            <option key={major.id} value={major.id}>{major.title}</option>
          ))}
        </Field>
      </li>
    );
  }
}
