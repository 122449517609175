import {useSelector} from "react-redux";
import {useAssessment} from "traitify-widgets";
import I18n from "common/lib/i18n";

const getBadge = (type) => {
  const detail = type.personality_type.details.find(({title}) => title === "Paradox - Badge");
  if(!detail) { return; }
  if(!detail.body) { return; }

  return {alt: type.personality_type.name, url: detail.body};
};

function Icons() {
  const assessment = useAssessment();

  if(!assessment) { return null; }
  if(!assessment.personality_types) { return null; }

  const types = assessment.personality_types;
  const image1 = getBadge(types[0]);
  const image2 = assessment.personality_blend && getBadge(types[1]);
  if(!image1) { return null; }

  return (
    <div className="career-heading-icons">
      <img alt={image1.alt} src={image1.url} />
      {image2 && (
        <>
          <div className="badge-divider" />
          <img alt={image2.alt} src={image2.url} />
        </>
      )}
    </div>
  );
}

export default function Heading() {
  const locale = useSelector((state) => state.locale);
  const organization = useSelector((state) => state.current.organization);
  const i18n = new I18n(locale, organization);

  return (
    <section className="flex items-center justify-between p-shadow-box mb-section">
      <div className="p-heading">{i18n.t("results.heading.careers")}</div>
      <Icons />
    </section>
  );
}
