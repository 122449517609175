import {connect} from "react-redux";
import {Loader} from "legacy/components/partials";
import Component from "./presenter";

const mapStateToProps = ({assessment}) => ({assessment});
const component = connect(mapStateToProps, {})(Component);

export default (props) => (
  <Loader component={component} componentProps={props} dependencies={["assessment"]} />
);
