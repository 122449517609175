import PropTypes from "prop-types";
import {useEffect} from "react";
import {useParams} from "react-router-dom";
import {Components} from "traitify-widgets";
import {Loading} from "legacy/components/partials";
import traitify from "legacy/lib/traitify";
import Heading from "./heading";

function Careers({
  assessment,
  assessments,
  loadAssessment,
  locale,
  organization
}) {
  const {assessmentID} = useParams();

  useEffect(() => { traitify.updateLocale(locale.code); }, [locale]);
  useEffect(() => {
    const deckIDs = organization.assessment_types
      .filter((type) => type.deck_type !== "COGNITIVE")
      .map((type) => type.deck_id);

    loadAssessment(
      assessmentID || assessments.find(
        ({completed_at: completedAt, deck_id: deckID}) => completedAt && deckIDs.includes(deckID)
      ).id
    );
  }, [assessmentID]);

  if(!assessment) { return <Loading />; }

  const traitifyProps = traitify.props;
  const widgetProps = {
    ...traitifyProps,
    assessmentID: assessment.id,
    options: {
      ...traitifyProps.options,
      career: {
        experienceLevels: [
          organization.options.experience_level_1 && 1,
          organization.options.experience_level_2 && 2,
          organization.options.experience_level_3 && 3,
          organization.options.experience_level_4 && 4,
          organization.options.experience_level_5 && 5
        ].filter(Boolean),
        highlightedPath: `/careers/highlighted?assessment_id=${assessment.id}`,
        path: `/careers?assessment_id=${assessment.id}`
      }
    }
  };

  if(organization.options.show_jobs) {
    widgetProps.options.career.jobs = {
      inline: organization.options.show_careers === "Inline Jobs",
      path: ({career}) => career && `/careers/${career.id}/jobs`,
      source: organization.options.show_jobs
    };
  }

  return (
    <div>
      <Components.Container {...widgetProps}>
        <Heading />
        <Components.Results.Career.Container />
      </Components.Container>
    </div>
  );
}

Careers.defaultProps = {
  assessment: null,
  assessments: null
};
Careers.propTypes = {
  assessment: PropTypes.shape({
    id: PropTypes.string.isRequired
  }),
  assessments: PropTypes.arrayOf(
    PropTypes.shape({
      completed_at: PropTypes.number,
      id: PropTypes.string.isRequired
    }).isRequired
  ),
  loadAssessment: PropTypes.func.isRequired,
  locale: PropTypes.shape({
    code: PropTypes.string.isRequired
  }).isRequired,
  organization: PropTypes.shape({
    assessment_types: PropTypes.arrayOf(String),
    options: PropTypes.shape({
      experience_level_1: PropTypes.bool,
      experience_level_2: PropTypes.bool,
      experience_level_3: PropTypes.bool,
      experience_level_4: PropTypes.bool,
      experience_level_5: PropTypes.bool,
      show_careers: PropTypes.string,
      show_jobs: PropTypes.string
    }).isRequired
  }).isRequired
};
export default Careers;
