import "core-js/stable";
import {useMemo} from "react";
import {RouterProvider} from "react-router-dom";
import "regenerator-runtime/runtime";
import createRouter from "legacy/application/router";
import "public/assets/style.scss";

export default function Application() {
  const router = useMemo(() => createRouter(), []);

  return <RouterProvider router={router} />;
}
