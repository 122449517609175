import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {useAssessment} from "traitify-widgets";
import BaseModal from "legacy/components/partials/modal";
import useI18n from "legacy/lib/hooks/use-i18n";
import Instructions from "./instructions";
import SelectLocale from "./select-locale";

function Modal({closeModal}) {
  const assessment = useAssessment();
  const i18n = useI18n();
  const [step, setStep] = useState(0);

  useEffect(() => { if(step > 1) { closeModal(); } }, [step]);

  if(!assessment) { return null; }

  const animated = assessment.deck_id ? (
    assessment.deck_id.toLowerCase().includes("animated")
    || assessment.deck_id === "big-five-hello-fresh"
    || assessment.deck_id === "big-five-r-and-d"
  ) : false;
  const nextStep = () => {
    if(!assessment.deck_id) { return closeModal(); }

    setStep((currentStep) => currentStep + 1);
  };
  const size = animated ? "large" : undefined;

  return (
    <BaseModal className="p-modal" onClose={closeModal} paradox={true} show={true} size={size} title={i18n.t("assessment.instructions")}>
      <div className="instructions">
        {step === 0 && <SelectLocale closeModal={closeModal} nextStep={nextStep} />}
        {step === 1 && <Instructions animated={animated} nextStep={nextStep} />}
      </div>
    </BaseModal>
  );
}

Modal.propTypes = {closeModal: PropTypes.func.isRequired};

export default Modal;
