import PropTypes from "prop-types";
import {Component} from "react";

const gradient = "progid:DXImageTransform.Microsoft.gradient";

export default class Stripe extends Component {
  static propTypes = {
    assessment: PropTypes.shape({
      personality_blend: PropTypes.shape({
        name: PropTypes.string.isRequired
      }),
      personality_types: PropTypes.arrayOf(
        PropTypes.shape({
          personality_type: PropTypes.shape({
            badge: PropTypes.shape({
              color_1: PropTypes.string.isRequired
            }).isRequired
          }).isRequired
        }).isRequired
      )
    }).isRequired
  };
  render() {
    const {assessment, ...props} = this.props;
    if(!assessment.personality_types) { return null; }

    const types = assessment.personality_types;
    const color1 = types[0].personality_type.badge.color_1;
    const color2 = assessment.personality_blend
      ? types[1].personality_type.badge.color_1
      : color1;

    props.className = props.className || "";
    props.className += " personality-stripe";
    props.style = {
      background: `linear-gradient(to right, #${color1}, #${color2})`,
      filter: `${gradient}(startColorstr='#${color1}', endColorstr='#${color2}', GradientType=1)`,
      ...props.style
    };

    return <div {...props} />;
  }
}
