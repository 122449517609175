import PropTypes from "prop-types";
import {useParams} from "react-router-dom";
import I18n from "common/lib/i18n";
import useMine from "legacy/lib/hooks/assessment/use-mine";
import compassImage from "public/assets/images/results/compass.png";

function Utilities({
  assessment,
  i18n,
  organization,
  shareAssessment
}) {
  const {adminToken} = useParams();
  const mine = useMine();
  const subject = `Traitify ${i18n.t("results.personality_results")}`.replace(/ /g, "%20");
  const body = i18n.t("results.email_body", {
    personality_url: `${organization.url}/personality/${assessment.id}`,
    session_url: `${organization.url}/sessions/new`
  }).replace(/ /g, "%20");
  const printUrl = adminToken
    ? `/personality/${assessment.id}.pdf?admin_token=${adminToken}`
    : `/personality/${assessment.id}.pdf`;

  return (
    <div className="page-utilities">
      <ul>
        {(mine && organization.options.allow_retake) && (
          <li>
            <a href={`/assessment?r=etry&deck_id=${assessment.deck_id}`} title={i18n.t("results.retake_assessment")}>
              <i className="fa fa-undo" />
              <br />
              {i18n.t("results.retake")}
            </a>
          </li>
        )}
        {["career-deck", "careers-short"].includes(assessment.deck_id) && (
          <li>
            <a href="https://cdn.traitify.com/creative/downloads/Traitify_SelfDiscoveryGuide.pdf" rel="noopener noreferrer" target="_blank" title="Self Discovery Guide">
              <img src={compassImage} alt="Guide" />
              <br />
              Guide
            </a>
          </li>
        )}
        <li>
          <a href={printUrl} rel="noopener noreferrer" target="_blank" title={i18n.t("results.print")}>
            <i className="fa fa-print" />
            <br />
            {i18n.t("results.print")}
          </a>
        </li>
        {mine && (
          <li>
            <a href={`mailto:?subject=${subject}&body=${body}`} rel="noopener noreferrer" target="_blank" title={i18n.t("results.email")}>
              <i className="fa fa-envelope-o" />
              <br />
              {i18n.t("results.email")}
            </a>
          </li>
        )}
        {organization.options.enable_sharing && (
          <li className="share-dropdown">
            <span>
              <i className="fa fa-share" />
              <br />
              {i18n.t("results.share")}
            </span>
            <ul>
              <li>
                <button title={i18n.t("results.share")} onClick={() => shareAssessment("facebook")} type="button">
                  <i className="fa fa-facebook" />
                  {i18n.t("results.facebook")}
                </button>
              </li>
              <li>
                <button title={i18n.t("results.twitter")} onClick={() => shareAssessment("twitter")} type="button">
                  <i className="fa fa-twitter" />
                  {i18n.t("results.twitter")}
                </button>
              </li>
              <li>
                <button title={i18n.t("results.linkedin")} onClick={() => shareAssessment("linkedin")} type="button">
                  <i className="fa fa-linkedin" />
                  {i18n.t("results.linkedin")}
                </button>
              </li>
            </ul>
          </li>
        )}
        <li>
          <a href={`mailto:${organization.contact_emails}`} rel="noopener noreferrer" target="_blank" title={i18n.t("results.contact_us")}>
            <i className="fa fa-life-ring" />
            <br />
            {i18n.t("results.help")}
          </a>
        </li>
        {(mine && window !== window.top) && (
          <li>
            <a href="/sessions" data-method="delete" title={i18n.t("results.logout")}>
              <i className="fa fa-power-off" />
              <br />
              {i18n.t("results.logout")}
            </a>
          </li>
        )}
      </ul>
    </div>
  );
}

Utilities.propTypes = {
  assessment: PropTypes.shape({
    deck_id: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired
  }).isRequired,
  i18n: PropTypes.instanceOf(I18n).isRequired,
  organization: PropTypes.shape({
    contact_emails: PropTypes.string.isRequired,
    options: PropTypes.shape({
      allow_retake: PropTypes.bool.isRequired,
      enable_sharing: PropTypes.bool.isRequired
    }).isRequired,
    url: PropTypes.string.isRequired
  }).isRequired,
  shareAssessment: PropTypes.func.isRequired
};

export default Utilities;
