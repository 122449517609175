import "common/lib/shakapacker-asset-fix";
import Rails from "@rails/ujs";
import {createRoot} from "react-dom/client";
import {Provider} from "react-redux";
import "whatwg-fetch";
import configureStore from "legacy/application/store";
import Application from "legacy/components/layout/application";

Rails.start();

document.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll(".react-component").forEach((node) => {
    const root = createRoot(node);

    root.render(
      <Provider store={configureStore()}>
        <Application />
      </Provider>
    );
  });
});
