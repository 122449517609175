import {combineReducers} from "redux";
import app from "./app";
import assessment from "./assessment";
import assessments from "./assessments";
import current from "./current";
import geolocation from "./geolocation";
import history from "./history";
import locale from "./locale";
import notifications from "./notifications";
import original from "./original";
import profile from "./profile";
import session from "./session";
import sessions from "./sessions";

export default combineReducers({
  app,
  assessment,
  assessments,
  current,
  history,
  locale,
  geolocation,
  notifications,
  original,
  profile,
  session,
  sessions
});
