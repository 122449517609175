// Requires current user to be an admin
export const LOAD_PROFILE = "LOAD_PROFILE";
export const REQUEST_PROFILE = "REQUEST_PROFILE";
export const REQUEST_PROFILE_FAILED = "REQUEST_PROFILE_FAILED";
export const REQUEST_PROFILE_FULFILLED = "REQUEST_PROFILE_FULFILLED";
export const REQUEST_PROFILE_REJECTED = "REQUEST_PROFILE_REJECTED";
export const REQUEST_PROFILE_SUCCEEDED = "REQUEST_PROFILE_SUCCEEDED";
export const STORE_PROFILE = "STORE_PROFILE";

export const loadProfile = (profileID) => ({type: LOAD_PROFILE, profileID});
export const requestProfile = (profileID) => ({type: REQUEST_PROFILE, profileID});
export const requestProfileFailed = (error) => ({type: REQUEST_PROFILE_FAILED, error});
export const requestProfileFulfilled = (data) => ({type: REQUEST_PROFILE_FULFILLED, data});
export const requestProfileRejected = (error) => ({type: REQUEST_PROFILE_REJECTED, error});
export const requestProfileSucceeded = (profile) => ({type: REQUEST_PROFILE_SUCCEEDED, profile});
export const storeProfile = (profile) => ({type: STORE_PROFILE, profile});
