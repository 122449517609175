import {combineEpics} from "redux-observable";
import app from "./app";
import assessment from "./assessment";
import assessments from "./assessments";
import notifications from "./notifications";
import passwords from "./passwords";
import profile from "./profile";
import sessions from "./sessions";

export default combineEpics(
  app,
  assessment,
  assessments,
  notifications,
  passwords,
  profile,
  sessions
);
