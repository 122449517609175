export const RESEND_CODE = "RESEND_CODE";
export const RESEND_CODE_FAILED = "SESSIONS_RESEND_CODE_FAILED";
export const RESEND_CODE_FULFILLED = "SESSIONS_RESEND_CODE_FULFILLED";
export const RESEND_CODE_REJECTED = "SESSIONS_RESEND_CODE_REJECTED";
export const RESEND_CODE_SUCCEEDED = "SESSIONS_RESEND_CODE_SUCCEEDED";

export const resendCode = () => ({type: RESEND_CODE});
export const resendCodeFailed = (error) => ({type: RESEND_CODE_FAILED, error});
export const resendCodeFulfilled = (response) => ({type: RESEND_CODE_FULFILLED, response});
export const resendCodeRejected = (error) => ({type: RESEND_CODE_REJECTED, error});
export const resendCodeSucceeded = (data) => ({type: RESEND_CODE_SUCCEEDED, data});
