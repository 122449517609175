import {connect} from "react-redux";
import {loadAssessment} from "legacy/constants/assessment";
import {updateCurrentRecommendation} from "legacy/constants/current";
import {assignLocation} from "legacy/constants/history";
import Component from "./presenter";

const mapStateToProps = ({
  assessment, assessments, current: {organization, recommendation}, locale
}) => ({
  assessment, assessments, locale, organization, recommendation
});

export default connect(
  mapStateToProps,
  {assignLocation, loadAssessment, updateCurrentRecommendation}
)(Component);
