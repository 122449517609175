import {combineEpics, ofType} from "redux-observable";
import {from, of} from "rxjs";
import {catchError, filter, map, mergeMap} from "rxjs/operators";
import {i18nAxios} from "legacy/lib/axios";
import {assignLocation} from "legacy/constants/history";
import {createNotification} from "legacy/constants/notifications";
import {
  CREATE_SESSION,
  CREATE_SESSION_FAILED,
  CREATE_SESSION_FULFILLED,
  CREATE_SESSION_MESSAGE,
  CREATE_SESSION_REJECTED,
  CREATE_SESSION_SUCCEEDED,
  createSession2fa,
  createSessionFailed,
  createSessionFulfilled,
  createSessionMessage,
  createSessionRejected,
  createSessionSucceeded
} from "legacy/constants/sessions";
import I18n from "common/lib/i18n";
import resendCodeEpic from "./resend-code";
import verifyCodeEpic from "./verify-code";

const createSession2faEpic = (action$) => action$.pipe(
  ofType(CREATE_SESSION_FULFILLED),
  filter(({response: {data}}) => data.require_2fa),
  map(createSession2fa)
);

const createSessionEpic = (action$, state$) => action$.pipe(
  ofType(CREATE_SESSION),
  mergeMap(({params}) => from(i18nAxios(state$.value.locale.code).post("/api/user/session", params)).pipe(
    map(createSessionFulfilled),
    catchError((error) => of(createSessionRejected(error.message)))
  ))
);

const createSessionFailedEpic = (action$) => action$.pipe(
  ofType(CREATE_SESSION_FAILED),
  filter(() => window.location.pathname === "/login"),
  map(({error}) => createNotification({kind: "error", message: error}))
);

const createSessionFulfilledEpic = (action$) => action$.pipe(
  ofType(CREATE_SESSION_FULFILLED),
  filter(({response: {data}}) => !data.passwordless),
  filter(({response: {data}}) => !data.require_2fa),
  map(({response: {data}}) => (
    data.success
      ? createSessionSucceeded(data)
      : createSessionFailed(data.errors)
  ))
);

const createSessionMessageEpic = (action$) => action$.pipe(
  ofType(CREATE_SESSION_MESSAGE),
  filter(() => window.location.pathname === "/login"),
  map(({message}) => createNotification({kind: "success", message}))
);

const createSessionPasswordlessEpic = (action$, state$) => action$.pipe(
  ofType(CREATE_SESSION_FULFILLED),
  filter(({response: {data}}) => data.passwordless),
  map(() => {
    const {current: {organization}, locale} = state$.value;
    const i18n = new I18n(locale, organization);

    return i18n.t("sessions.passwordless_login.post");
  }),
  map(createSessionMessage)
);

const createSessionRejectedEpic = (action$) => action$.pipe(
  ofType(CREATE_SESSION_REJECTED),
  map(({error}) => error),
  map(createSessionFailed)
);

const createSessionSucceededEpic = (action$) => action$.pipe(
  ofType(CREATE_SESSION_SUCCEEDED),
  map(({data: {redirect}}) => (
    assignLocation(
      redirect || (window.location.pathname === "/login" ? "/choose-organization" : "/"),
      true
    )
  ))
);

export default combineEpics(
  createSession2faEpic,
  createSessionEpic,
  createSessionFailedEpic,
  createSessionFulfilledEpic,
  createSessionMessageEpic,
  createSessionPasswordlessEpic,
  createSessionRejectedEpic,
  createSessionSucceededEpic,
  resendCodeEpic,
  verifyCodeEpic
);
