import {connect} from "react-redux";
import I18n from "common/lib/i18n";
import {createPasswordReset} from "legacy/constants/passwords";
import Component from "./presenter";

const mapStateToProps = ({
  current: {organization}, locale, sessions
}) => ({
  i18n: new I18n(locale, organization),
  organization,
  sessions
});

export default connect(
  mapStateToProps,
  {createPasswordReset}
)(Component);
