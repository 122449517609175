import PropTypes from "prop-types";
import {Component} from "react";
import I18n from "common/lib/i18n";
import FieldWithoutLabel from "legacy/components/partials/form/field";
import FieldWithLabel from "legacy/components/partials/form/field-with-label";

export default class PasswordField extends Component {
  static propTypes = {
    complex: PropTypes.bool,
    i18n: PropTypes.instanceOf(I18n).isRequired,
    onChange: PropTypes.func.isRequired,
    password: PropTypes.string,
    passwordConfirmation: PropTypes.string,
    registration: PropTypes.bool.isRequired
  };
  static defaultProps = {complex: false, password: null, passwordConfirmation: null};
  constructor(props) {
    super(props);
    this.state = {validations: {}};
  }
  componentDidMount() {
    this.validate();
  }
  componentDidUpdate(prevProps) {
    const changes = {
      password: prevProps.password !== this.props.password,
      passwordConfirmation: prevProps.passwordConfirmation !== this.props.passwordConfirmation
    };

    if(changes.password || changes.passwordConfirmation) { this.validate(); }
  }
  validate() {
    const {complex, password, passwordConfirmation} = this.props;
    const validations = {};

    if(!password) { return this.setState({validations}); }

    if(/(?=.*[a-z])/.test(password)) { validations.lowercase = true; }
    if(/(?=.*[A-Z])/.test(password)) { validations.uppercase = true; }
    if(/(?=.*[0-9])/.test(password)) { validations.number = true; }
    if(password === passwordConfirmation) { validations.match = true; }
    if(complex) {
      if(/(?=.*[ !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~])/.test(password)) { validations.special = true; }
      if(/.{15,}/.test(password)) { validations.total = true; }
    } else if(/.{8,}/.test(password)) {
      validations.total = true;
    }

    this.setState({validations});
  }
  render() {
    const {complex, i18n, onChange, password, passwordConfirmation, registration} = this.props;
    const {validations} = this.state;
    const displayValidations = password !== null || passwordConfirmation !== null;
    const Field = registration ? FieldWithoutLabel : FieldWithLabel;

    return (
      <li className="flex">
        <div className="flex-1 mr-buffer">
          <Field name="password" namespace="account" onChange={onChange} type="password" value={password} />
          {displayValidations && (
            <ul className="validations">
              {complex ? [
                <li key="total" className={validations.total && "validated"}>{i18n.t("validations.password.total", {minimum: 15})}</li>,
                <li key="special" className={validations.special && "validated"}>{i18n.t("validations.password.special")}</li>
              ] : (
                <li className={validations.total && "validated"}>{i18n.t("validations.password.total", {minimum: 8})}</li>
              )}
              <li className={validations.uppercase && "validated"}>{i18n.t("validations.password.uppercase")}</li>
              <li className={validations.lowercase && "validated"}>{i18n.t("validations.password.lowercase")}</li>
              <li className={validations.number && "validated"}>{i18n.t("validations.password.number")}</li>
            </ul>
          )}
        </div>
        <div className="flex-1">
          <Field name="password_confirmation" namespace="account" onChange={onChange} type="password" value={passwordConfirmation} />
          {displayValidations && (
            <ul className="validations">
              <li className={validations.match && "validated"}>{i18n.t("validations.password.match")}</li>
            </ul>
          )}
        </div>
      </li>
    );
  }
}
