import PropTypes from "prop-types";
import closeIcon from "public/assets/images/modal/close.png";

function CenterModal({children, className, onClose, show, size, title, ...props}) {
  const classNames = [
    className,
    "modal",
    `modal--${size}`,
    show && "modal--show"
  ].filter(Boolean);

  return (
    <div className={`flex flex-col ${classNames.join(" ")}`} role="dialog" {...props}>
      <div>
        <section className="box modal__center__container">
          <div className="close_container">
            <button aria-label="Close" className="close" onClick={onClose} type="button">
              <img alt="close" src={closeIcon} />
            </button>
          </div>
          {title && (
            <div className="box__title modal__title">
              <h2>{title}</h2>
            </div>
          )}
          <div className="box__content modal__video__content">
            {children}
          </div>
        </section>
      </div>
    </div>
  );
}

CenterModal.defaultProps = {className: null, paradox: false, size: "xlarge", title: null};
CenterModal.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  paradox: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  size: PropTypes.string,
  title: PropTypes.string
};

export default CenterModal;
