import {combineEpics, ofType} from "redux-observable";
import {from, of} from "rxjs";
import {catchError, concatMap, filter, map, mapTo, mergeMap} from "rxjs/operators";
import traitify from "legacy/lib/traitify";
import {
  LOAD_DEPENDENCY,
  dependencyError,
  dependencyLoaded,
  dependencyLoading
} from "legacy/constants/app";
import {updateCurrentProfile} from "legacy/constants/current";
import {
  LOAD_PROFILE,
  REQUEST_PROFILE,
  REQUEST_PROFILE_FAILED,
  REQUEST_PROFILE_FULFILLED,
  REQUEST_PROFILE_REJECTED,
  REQUEST_PROFILE_SUCCEEDED,
  STORE_PROFILE,
  loadProfile,
  requestProfile,
  requestProfileFailed,
  requestProfileFulfilled,
  requestProfileRejected,
  requestProfileSucceeded,
  storeProfile
} from "legacy/constants/profile";

const dependencyEpic = (action$, state$) => action$.pipe(
  ofType(LOAD_DEPENDENCY),
  filter(({dependency}) => dependency === "profile"),
  filter(() => !state$.value.app.dependencies.loaded
    .find((dependency) => dependency === "profile")),
  filter(() => !state$.value.app.dependencies.loading
    .find((dependency) => dependency === "profile")),
  map(loadProfile)
);

const dependencyLoadedEpic = (action$, state$) => action$.pipe(
  ofType(STORE_PROFILE),
  concatMap(({profile}) => {
    const actions = [dependencyLoaded("profile")];
    const {user} = state$.value.current;

    if(profile && user && profile.id === user.profile_uuid) {
      actions.unshift(updateCurrentProfile(profile));
    }

    return actions;
  })
);

const loadProfileEpic = (action$, state$) => action$.pipe(
  ofType(LOAD_PROFILE),
  map(() => (
    (state$.value.current.user || {}).profile_uuid
      ? requestProfile()
      : storeProfile(null)
  ))
);

const loadingProfileEpic = (action$) => action$.pipe(
  ofType(REQUEST_PROFILE),
  mapTo("profile"),
  map(dependencyLoading)
);

const requestProfileEpic = (action$, state$) => action$.pipe(
  ofType(REQUEST_PROFILE),
  map(() => state$.value.current.user.profile_uuid),
  mergeMap((profileID) => from(
    traitify.http.get(`/profiles/${profileID}?locale_key=${state$.value.locale.code}`)
  ).pipe(
    map(requestProfileFulfilled),
    catchError((error) => of(requestProfileRejected(error.message)))
  ))
);

const requestProfileFailedEpic = (action$) => action$.pipe(
  ofType(REQUEST_PROFILE_FAILED),
  map(({error}) => dependencyError("profile", error))
);

const requestProfileFulfilledEpic = (action$) => action$.pipe(
  ofType(REQUEST_PROFILE_FULFILLED),
  map(({data}) => (
    data.error
      ? requestProfileFailed(data.error)
      : requestProfileSucceeded(data)))
);

const requestProfileRejectedEpic = (action$) => action$.pipe(
  ofType(REQUEST_PROFILE_REJECTED),
  map(({error}) => error),
  map(requestProfileFailed)
);

const requestProfileSucceededEpic = (action$) => action$.pipe(
  ofType(REQUEST_PROFILE_SUCCEEDED),
  map(({profile}) => profile),
  map(storeProfile)
);

export default combineEpics(
  dependencyEpic,
  dependencyLoadedEpic,
  loadProfileEpic,
  loadingProfileEpic,
  requestProfileEpic,
  requestProfileFailedEpic,
  requestProfileFulfilledEpic,
  requestProfileRejectedEpic,
  requestProfileSucceededEpic
);
