import PropTypes from "prop-types";
import {useState} from "react";
import I18n from "common/lib/i18n";
import {Modal} from "legacy/components/partials";

function VerifyCodeModal({
  i18n,
  onClose,
  resendCode,
  sessions: {tfa},
  show,
  verifyCode
}) {
  const [code, setCode] = useState("");
  const [resent, setResent] = useState(false);
  const onChange = ({target: {value}}) => setCode(value);
  const onResend = () => {
    setCode("");
    setResent(true);
    resendCode();
  };
  const onSubmit = (e) => {
    e.preventDefault();

    setResent(false);
    verifyCode(code);
  };

  return (
    <Modal className="p-modal" onClose={onClose} paradox={true} show={show} title={i18n.t("verify_code.heading")}>
      <form className="p-form-default" onSubmit={onSubmit}>
        <ul>
          <section className="modal__inputs">
            <li>
              <p>{i18n.t("verify_code.sent")}.</p>
              <p>{i18n.t("verify_code.enter_below")}.</p>
            </li>
            <li>
              <label className="label" htmlFor="code">{i18n.t("attributes.code")}</label>
              <input className="p-form-field-lg" name="code" onChange={onChange} placeholder={i18n.t("attributes.code")} type="text" value={code} />
            </li>
            {tfa.error && <li className="error">{tfa.error}</li>}
            {resent ? (
              <li key="resent">
                <small>{i18n.t("verify_code.resent")}</small>
              </li>
            ) : (
              <li key="resend">
                <span>{i18n.t("verify_code.not_seeing_email")}</span>
                <button onClick={onResend} type="button"><b>{i18n.t("verify_code.resend_code")}.</b></button>
              </li>
            )}
          </section>
          <section className="modal__options">
            <button className="m-buffer-sm p-btn-blue" type="submit">{i18n.t("submit")}</button>
          </section>
        </ul>
      </form>
    </Modal>
  );
}

VerifyCodeModal.propTypes = {
  i18n: PropTypes.instanceOf(I18n).isRequired,
  onClose: PropTypes.func.isRequired,
  resendCode: PropTypes.func.isRequired,
  sessions: PropTypes.shape({
    tfa: PropTypes.shape({error: PropTypes.string}).isRequired
  }).isRequired,
  show: PropTypes.bool.isRequired,
  verifyCode: PropTypes.func.isRequired
};

export default VerifyCodeModal;
