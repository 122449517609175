import {combineEpics, ofType} from "redux-observable";
import {delay, map} from "rxjs/operators";
import {v4 as createUUID} from "uuid";
import {
  ADD_NOTIFICATION,
  CREATE_NOTIFICATION,
  CREATE_NOTIFICATION_FROM_OBJECT,
  addNotification,
  createNotification,
  createNotificationFromObject,
  deleteNotification
} from "legacy/constants/notifications";
import {last} from "common/lib/ext/array";
import I18n from "common/lib/i18n";

const addNotificationEpic = (action$) => action$.pipe(
  ofType(ADD_NOTIFICATION),
  delay(5000),
  map(({notification: {id}}) => deleteNotification(id))
);

const createNotificationEpic = (action$) => action$.pipe(
  ofType(CREATE_NOTIFICATION),
  map(({notification}) => (
    typeof notification.message === "object"
      ? createNotificationFromObject(notification)
      : addNotification({id: notification.id || createUUID(), ...notification})
  ))
);

const createNotificationFromObjectEpic = (action$, state$) => action$.pipe(
  ofType(CREATE_NOTIFICATION_FROM_OBJECT),
  map(({notification}) => {
    const {current: {organization}, locale} = state$.value;
    const i18n = new I18n(locale, organization);
    const message = Object.keys(notification.message).map((key) => (
      notification.message[key].map((value) => {
        const attribute = last(key.split("."));
        return [i18n.t(`attributes.${attribute}`) || attribute, value].join(" ").trim();
      }).join(", ")
    )).join(", ");
    return createNotification({kind: notification.kind, message});
  })
);

export default combineEpics(
  addNotificationEpic,
  createNotificationEpic,
  createNotificationFromObjectEpic
);
