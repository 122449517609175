import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import I18n from "common/lib/i18n";
import {Modal} from "legacy/components/partials";
import ForgotPasswordModal from "../forgot-password";
import VerifyCodeModal from "../verify-code";

function AdminModal({createSession, i18n, onClose, sessions, show}) {
  const [account, setAccount] = useState({});
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showVerifyCode, setShowVerifyCode] = useState(false);
  const onChange = ({target: {name, value}}) => setAccount({...account, [name]: value});
  const onSubmit = (e) => {
    e.preventDefault();

    createSession({account});
  };

  useEffect(() => { setShowVerifyCode(sessions.require2fa); }, [sessions.require2fa]);
  useEffect(() => {
    setShowForgotPassword(false);
    setShowVerifyCode(false);
  }, [show]);

  const showLogin = show && !showForgotPassword && !showVerifyCode;

  return (
    <>
      <Modal className="p-modal" onClose={onClose} paradox={true} show={showLogin} title={i18n.t("sessions.admin_login")}>
        <form className="p-form-default" onSubmit={onSubmit}>
          <ul>
            <section className="modal__inputs">
              <li>
                <label className="label" htmlFor="email">{i18n.t("attributes.email")}</label>
                <input className="p-form-field-lg" name="email" onChange={onChange} placeholder={i18n.t("attributes.example_email")} type="email" value={account.email || ""} />
              </li>
              <li>
                <label className="label" htmlFor="password">{i18n.t("attributes.password")}</label>
                <input className="p-form-field-lg" name="password" onChange={onChange} placeholder="******" type="password" value={account.password || ""} />
                <button className="block ml-auto" onClick={() => setShowForgotPassword(true)} type="button">{i18n.t("sessions.forgot_password")}</button>
              </li>
              {sessions.error && <li className="error">{sessions.error}</li>}
              {sessions.message && <li>{sessions.message}</li>}
            </section>
            <section className="modal__options">
              <button className="m-buffer-sm p-btn-blue" type="submit">{i18n.t("sessions.login")}</button>
            </section>
          </ul>
        </form>
      </Modal>
      <ForgotPasswordModal email={account.email} onClose={onClose} show={showForgotPassword} />
      <VerifyCodeModal onClose={onClose} show={showVerifyCode} />
    </>
  );
}

AdminModal.propTypes = {
  createSession: PropTypes.func.isRequired,
  i18n: PropTypes.instanceOf(I18n).isRequired,
  onClose: PropTypes.func.isRequired,
  sessions: PropTypes.shape({
    error: PropTypes.string,
    message: PropTypes.string,
    require2fa: PropTypes.bool.isRequired
  }).isRequired,
  show: PropTypes.bool.isRequired
};

export default AdminModal;
