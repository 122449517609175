import PropTypes from "prop-types";
import {toSnakeCase} from "common/lib/ext/string";
import I18n from "common/lib/i18n";

function PdfLink({adminToken, assessmentID, current: {recommendation, report}, i18n}) {
  let queryParams = {admin_token: adminToken};

  if(recommendation) { queryParams.recommendation_id = recommendation.recommendation_id; }
  if(report) {
    queryParams.perspective = toSnakeCase(report.perspective);
    queryParams.view = report.view;
  }
  queryParams = Object.keys(queryParams).map((key) => (
    `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`
  )).join("&");

  const href = `/personality/${assessmentID}.pdf?${queryParams}`;
  const title = i18n.t("results.print");

  return (
    <div className="nav-item">
      <a className="nav-link" href={href} rel="noopener noreferrer" target="_blank" title={title}>
        <i alt={title} className="fa fa-print" />
      </a>
    </div>
  );
}

PdfLink.propTypes = {
  adminToken: PropTypes.string.isRequired,
  assessmentID: PropTypes.string.isRequired,
  current: PropTypes.shape({
    recommendation: PropTypes.shape({recommendation_id: PropTypes.string}),
    report: PropTypes.shape({perspective: PropTypes.string, view: PropTypes.string})
  }).isRequired,
  i18n: PropTypes.instanceOf(I18n).isRequired
};

export default PdfLink;
