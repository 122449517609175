import {redirect} from "react-router-dom";
import {
  ASSIGN_LOCATION,
  RELOAD_LOCATION
} from "legacy/constants/history";

export default (
  state = {},
  action
) => {
  switch(action.type) {
    case ASSIGN_LOCATION:
      action.force
        ? window.location.assign(action.location)
        : redirect(action.location);

      return action;
    case RELOAD_LOCATION:
      window.location.reload();

      return action;
    default:
      return state;
  }
};
