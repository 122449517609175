import PropTypes from "prop-types";
import I18n from "common/lib/i18n";
import DefaultLocalePicker from "legacy/components/partials/locale-picker";
import useToggle from "legacy/lib/hooks/use-toggle";

function LocalePicker({current: {organization}, i18n, setLocale}) {
  const [showDropdown, toggleShowDropdown] = useToggle();
  const onChange = (value) => {
    const newLocale = organization.locales.find(({code}) => code === value);

    newLocale && setLocale(newLocale);
  };

  return (
    <>
      <DefaultLocalePicker className="hidden md-block" />
      <div className="md-hidden">
        <button className="flex items-center justify-between w-full" onClick={toggleShowDropdown} type="button">
          <span>{i18n.t("attributes.language")}</span>
          <i className={`fa ${showDropdown ? "fa-minus" : "fa-plus"}`} />
        </button>
        {showDropdown && (
          <div className="mt-2 px-2">
            {organization.locales.map(({code, name}) => (
              <button key={code} className="btn-link block no-underline" onClick={() => onChange(code)} type="button">{name}</button>
            ))}
          </div>
        )}
      </div>
    </>
  );
}

LocalePicker.propTypes = {
  current: PropTypes.shape({
    organization: PropTypes.shape({
      locales: PropTypes.arrayOf(PropTypes.shape({
        code: PropTypes.string,
        name: PropTypes.string
      }))
    }).isRequired
  }).isRequired,
  i18n: PropTypes.instanceOf(I18n).isRequired,
  setLocale: PropTypes.func.isRequired
};

export default LocalePicker;
