import PropTypes from "prop-types";
import {Component} from "react";
import I18n from "common/lib/i18n";
import {DangerousHTML, Modal} from "legacy/components/partials";
import Axios, {i18nAxios} from "legacy/lib/axios";

export default class DeleteModal extends Component {
  static propTypes = {
    assignLocation: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    i18n: PropTypes.instanceOf(I18n).isRequired,
    locale: PropTypes.shape({
      code: PropTypes.string.isRequired
    }).isRequired
  };
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentWillUnmount() {
    const {request} = this.state;
    request && request.cancel();
  }
  onDelete = () => {
    const {assignLocation, locale} = this.props;
    const axios = i18nAxios(locale.code);
    const request = Axios.CancelToken.source();

    axios.delete("/api/user/user", {}, {
      cancelToken: request.token
    }).then(({data: {errors, success}}) => {
      if(success) {
        this.setState({errors: null, request: null}, () => {
          assignLocation("/sessions/new", true);
        });
      } else {
        this.setState({errors, request: null});
      }
    });

    this.setState({request});
  };
  render() {
    const {closeModal, i18n} = this.props;
    const {errors} = this.state;

    return (
      <Modal className="p-modal" onClose={closeModal} paradox={true} show={true} title={i18n.t("settings.delete_heading")}>
        <section className="modal__inputs items-start p-buffer-lg">
          <p className="mt-0">{i18n.t("settings.delete_text")}</p>
          <DangerousHTML html={i18n.t("settings.delete_html")} />
          {errors && <p className="error">{errors}</p>}
        </section>
        <section className="modal__options">
          <button className="p-btn-theme m-buffer-sm" onClick={closeModal} type="button">{i18n.t("cancel")}</button>
          <button className="p-btn-red m-buffer-sm" onClick={this.onDelete} type="button">{i18n.t("settings.delete_profile")}</button>
        </section>
      </Modal>
    );
  }
}
