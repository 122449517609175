import PropTypes from "prop-types";
import {useState} from "react";
import I18n from "common/lib/i18n";
import * as Modals from "legacy/components/pages/sessions/new/modals";
import LocalePicker from "./locale-picker";

function SessionLinks({current, i18n, updateLocale}) {
  const {organization: {options}} = current;
  const [showAdminModal, setShowAdminModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);

  const isAdminResults = window.location.pathname.startsWith("/admin");
  const isLogin = window.location.pathname === "/login";
  const showAdmin = !isLogin && options.show_admin_login;
  const showLogin = !isLogin && (
    options.capture_password || (options.capture_external_id && !options.capture_email)
  );

  return (
    <>
      {!isAdminResults && (
        <div className="nav-item">
          <LocalePicker current={current} i18n={i18n} setLocale={updateLocale} />
        </div>
      )}
      {!isAdminResults && (showLogin || showAdmin) && (
        <div className="border-gray border-t-solid border-t my-4 md-hidden" />
      )}
      {showLogin && (
        <div className="nav-item">
          <button
            className="nav-link"
            onClick={() => setShowLoginModal(true)}
            type="button"
          >
            {i18n.t("sessions.login")}
          </button>
        </div>
      )}
      {showAdmin && (
        <div className="nav-item">
          <button
            className="nav-link"
            onClick={() => setShowAdminModal(true)}
            type="button"
          >
            {i18n.t("sessions.admin_login")}
          </button>
        </div>
      )}
      {showAdmin && (
        <Modals.Admin onClose={() => setShowAdminModal(false)} show={showAdminModal} />
      )}
      {showLogin && <Modals.Login onClose={() => setShowLoginModal(false)} show={showLoginModal} />}
    </>
  );
}

SessionLinks.propTypes = {
  current: PropTypes.shape({
    organization: PropTypes.shape({
      options: PropTypes.shape({
        capture_email: PropTypes.bool,
        capture_external_id: PropTypes.bool,
        capture_password: PropTypes.bool,
        show_admin_login: PropTypes.bool
      }).isRequired
    }).isRequired
  }).isRequired,
  i18n: PropTypes.instanceOf(I18n).isRequired,
  updateLocale: PropTypes.func.isRequired
};

export default SessionLinks;
