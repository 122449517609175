import {STORE_APP} from "legacy/constants/app";

export default (
  state = {},
  action
) => {
  switch(action.type) {
    case STORE_APP:
      return {
        ...state,
        ...action.app.original
      };
    default:
      return state;
  }
};
