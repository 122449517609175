import {useCallback, useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import Axios, {i18nAxios} from "legacy/lib/axios";
import traitify from "legacy/lib/traitify";
import useInitialAssessments from "./use-initial-assessments";

// NOTE: Ensures requests to trigger integrations are finished before redirecting
export default function useSurveyEffect() {
  const [completed, setCompleted] = useState(false);
  const [completedIDs, setCompletedIDs] = useState([]);
  const initialAssessments = useInitialAssessments();
  const [loading, setLoading] = useState(false);
  const locale = useSelector((state) => state.locale);
  const requests = useRef([]);
  const onFinished = useCallback(({assessment}) => {
    const skippableIDs = (initialAssessments || [])
      .filter((a) => a.completed).map(({id}) => id);
    if(skippableIDs.includes(assessment.id)) { return; }

    const request = {id: assessment.id, source: Axios.CancelToken.source()};
    requests.current.push(request);

    i18nAxios(locale.code).put(`/api/user/assessments/${assessment.id}/complete`, {
      cancelToken: request.source.token
    }).then(() => {
      requests.current = requests.current.filter(({id}) => id !== request.id);

      setCompletedIDs((ids) => [...ids, assessment.id]);
    });
  }, [initialAssessments]);

  useEffect(() => traitify.listener.on("Survey.finished", onFinished), [onFinished]);
  useEffect(() => traitify.listener.on("Surveys.finished", () => setLoading(true)), []);
  useEffect(() => {
    if(!loading) { return; }
    if(!initialAssessments) { return; }

    const requiredIDs = initialAssessments.filter((a) => !a.completed).map(({id}) => id);

    setCompleted(requiredIDs.every((id) => completedIDs.includes(id)));
  }, [completedIDs, initialAssessments, loading]);

  useEffect(() => (
    () => requests.current.forEach((request) => request.source.cancel())
  ), []);

  return {completed, loading};
}
