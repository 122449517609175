import appRequest from "./request";
import dependencies from "./dependencies";

export default (
  state = {},
  action
) => ({
  ...appRequest(state, action),
  dependencies: dependencies(state.dependencies || {}, action)
});
