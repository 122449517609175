import PropTypes from "prop-types";
import {useEffect, useRef, useState} from "react";
import DatePicker from "react-datepicker";
import I18n from "common/lib/i18n";
import useQueryParams from "common/hooks/use-query-params";
import {DangerousHTML, Loading} from "legacy/components/partials";
import FieldWithoutLabel from "legacy/components/partials/form/field";
import FieldWithLabel from "legacy/components/partials/form/field-with-label";
import Axios, {i18nAxios} from "legacy/lib/axios";
import DataFields from "./data-fields";
import LocaleField from "./locale-field";
import MajorField from "./major-field";
import PackageField from "./package-field";
import PasswordField from "./password-field";
import RegistrationDisclaimer from "./registration-disclaimer";

const blankChanges = {account: {}, data: [], profile: {}, user: {}};

function Form({
  assignLocation,
  createNotification,
  current: {organization},
  i18n,
  locale,
  type,
  updateCurrent
}) {
  const queryParams = useQueryParams();
  const token = queryParams.get("token");
  const [changes, setChanges] = useState({...blankChanges});
  const [domainError, setDomainError] = useState(false);
  const [externalIDError, setExternalIDError] = useState(false);
  const [original, setOriginal] = useState(null);
  const [packageID, setPackageID] = useState(null);
  const [passwordlessError, setPasswordlessError] = useState(false);
  const fetchRequest = useRef(null);
  const passwordlessRequest = useRef(null);
  const submitRequest = useRef(null);
  const {departments, options} = organization;
  const registration = type === "Registration";

  useEffect(() => {
    const axios = i18nAxios(locale.code);

    fetchRequest.current = axios.get("/api/user/account", {
      cancelToken: Axios.CancelToken.source().token,
      params: {token}
    }).then(({data: {account}}) => {
      fetchRequest.current = null;

      if(!account) { return setOriginal({...blankChanges}); }

      const user = account.users.find(({organization: {id}}) => id === organization.id);

      if(user) { return setOriginal({account, data: user.data, profile: user.profile, user}); }

      setOriginal({...blankChanges, account});
    });

    return () => {
      if(fetchRequest.current) { fetchRequest.current.cancel(); }
      if(passwordlessRequest.current) { passwordlessRequest.current.cancel(); }
      if(submitRequest.current) { submitRequest.current.cancel(); }
    };
  }, [token]);

  useEffect(() => {
    const newChanges = {...changes};

    [
      {model: "account", fields: ["birthdate", "email", "first_name", "last_name", "locale"]},
      {model: "profile", fields: ["external_id"]},
      {model: "user", fields: ["account_type", "department", "title"]}
    ].forEach(({fields, model}) => {
      newChanges[model] = {...newChanges[model]};

      fields.filter((field) => queryParams.has(field)).forEach((field) => {
        newChanges[model][field] = queryParams.get(field);
      });
    });

    if(registration) { newChanges.user.invited = false; }

    setChanges(newChanges);
  }, [queryParams.toString()]);

  if(!original) { return <Loading />; }

  const onChange = (model, {target: {name, value}}) => {
    const currentChanges = {...changes[model]};
    const currentValue = original[model][name];
    if(currentValue === value || (!currentValue && !value)) {
      delete currentChanges[name];
    } else {
      currentChanges[name] = value;
    }

    setChanges({...changes, [model]: currentChanges});
  };
  const pendingChanges = (
    Object.keys(changes.account).length > 0
    || changes.data.length > 0
    || Object.keys(changes.profile).length > 0
    || Object.keys(changes.user).length > 0
  );
  const value = (model, name) => {
    let current = changes[model][name];
    if(current == null) { current = original[model][name]; }

    return current;
  };

  const onAccountChange = (e) => onChange("account", e);
  const onDataChange = (data) => setChanges({...changes, data});
  const onPasswordlessLogin = () => {
    const axios = i18nAxios(locale.code);
    const params = {account: {email: value("profile", "email") || value("account", "email")}};

    passwordlessRequest.current = axios.post("/api/user/password/send_login_email", params, {
      cancelToken: Axios.CancelToken.source().token
    }).then(({data: {errors, message, success}}) => {
      passwordlessRequest.current = null;

      if(!success) { return createNotification({kind: "error", message: errors}); }

      createNotification({kind: "success", message});
    });
  };
  const onBirthdateChange = (val) => {
    onChange("profile", {target: {name: "date_of_birth", value: val}});
  };
  const onProfileChange = (e) => onChange("profile", e);
  const onSubmit = (e) => {
    e.preventDefault();

    if(!pendingChanges) { return; }
    if(registration && options.capture_package && !packageID) {
      return createNotification({
        kind: "error",
        message: {package: [i18n.t("errors.messages.blank")]}
      });
    }

    const axios = i18nAxios(locale.code);
    const method = original.account.id ? "put" : "post";
    const params = {
      account: {
        ...changes.account,
        data_attributes: [...changes.data],
        users_attributes: [{
          ...changes.user,
          id: original.user.id,
          organization_id: organization.id,
          profile_attributes: {...changes.profile}
        }]
      }
    };

    if(changes.profile.date_of_birth) { params.account.birthdate = changes.profile.date_of_birth; }
    if(changes.profile.email) { params.account.email = changes.profile.email; }
    if(changes.profile.first_name) { params.account.first_name = changes.profile.first_name; }
    if(changes.profile.last_name) { params.account.last_name = changes.profile.last_name; }
    if(changes.profile.primary_language) {
      params.account.locale = changes.profile.primary_language;
    }

    submitRequest.current = axios[method]("/api/user/account", params, {
      cancelToken: Axios.CancelToken.source().token
    }).then(({data: {account, current, errors, redirect, success}}) => {
      submitRequest.current = null;

      if(!success) {
        setDomainError((errors.email || []).includes(i18n.t("errors.messages.domain")));
        setExternalIDError((errors["users.external_id"] || []).includes(i18n.t("errors.messages.taken")));
        setPasswordlessError((errors.email || []).includes(i18n.t("errors.messages.taken")));

        return createNotification({kind: "error", message: errors});
      }

      if(registration) {
        return assignLocation(
          packageID && packageID !== "skip"
            ? `/recommendation/survey?package_id=${packageID}`
            : redirect || "/assessment",
          true
        );
      }

      const user = account.users.find(({organization_id: id}) => id === organization.id);

      setChanges({...blankChanges});
      setOriginal({account, data: user.data, profile: user.profile, user});
      createNotification({kind: "success", message: i18n.t("settings.updated")});
      updateCurrent(current);
    });
  };
  const onUserChange = (e) => onChange("user", e);
  const Field = registration ? FieldWithoutLabel : FieldWithLabel;
  const birthdate = value("profile", "date_of_birth") || value("account", "birthdate");

  return (
    <form className="p-form-default" onSubmit={onSubmit}>
      <ul>
        {domainError && (
          <li>
            <DangerousHTML html={i18n.t("sessions.invalid_domain")} tag="p" />
          </li>
        )}
        {options.capture_first_and_last_name && (
          <li className="flex">
            <div className="flex-1 mr-buffer">
              <Field
                name="first_name"
                namespace="profile"
                onChange={onProfileChange}
                value={value("profile", "first_name") || value("account", "first_name")}
              />
            </div>
            <div className="flex-1">
              <Field
                name="last_name"
                namespace="profile"
                onChange={onProfileChange}
                value={value("profile", "last_name") || value("account", "last_name")}
              />
            </div>
          </li>
        )}
        {options.capture_email && (
          <>
            <li>
              <Field
                name="email"
                namespace="profile"
                onChange={onProfileChange}
                type="email"
                value={value("profile", "email") || value("account", "email")}
              />
            </li>
            {passwordlessError && (
              <li>
                {i18n.t("sessions.passwordless_login.pre")} <button className="btn--link" onClick={onPasswordlessLogin} type="button">{i18n.t("click_here")}</button>. {i18n.t("sessions.passwordless_login.post")}
              </li>
            )}
          </>
        )}
        {options.capture_password && !original.account.password_digest && (
          <PasswordField
            complex={options.require_complex_password}
            onChange={onAccountChange}
            password={value("account", "password")}
            passwordConfirmation={value("account", "password_confirmation")}
            registration={registration}
          />
        )}
        {options.capture_birthdate && (
          <li>
            <label key="label" className="label" htmlFor="date_of_birth">{i18n.t("attributes.birthdate")}</label>
            <DatePicker
              selected={birthdate ? new Date(birthdate) : null}
              onChange={onBirthdateChange}
              inline={true}
              id="date_of_birth"
              name="date_of_birth"
              showYearDropdown={true}
              dropdownMode="select"
              dateFormat="yyyy-MM-dd"
            />
          </li>
        )}
        <LocaleField onChange={onProfileChange} original={original} registration={registration} />
        {options.capture_external_id && (
          <>
            <li>
              <Field
                name="external_id"
                namespace="profile"
                onChange={onProfileChange}
                value={value("profile", "external_id")}
              />
            </li>
            {externalIDError && (
              <li>
                {i18n.t("sessions.returning_user")} {i18n.t("sessions.signin_first")}
              </li>
            )}
          </>
        )}
        {options.capture_account_type && options.account_types.length > 0 && (
          <li>
            <Field
              name="account_type"
              namespace="user"
              onChange={onUserChange}
              type="select"
              value={value("user", "account_type")}
            >
              {options.account_types.map((accountType) => (
                <option key={accountType} value={accountType}>{accountType}</option>
              ))}
            </Field>
          </li>
        )}
        {options.capture_major && (
          <MajorField onChange={onUserChange} value={value("user", "major_id")} registration={registration} />
        )}
        {options.capture_department && departments.length > 0 && (
          <li>
            <Field
              name="department"
              namespace="user"
              onChange={onUserChange}
              type="select"
              value={value("user", "department")}
            >
              {departments.map(({id, name}) => (
                <option key={id} value={name}>{name}</option>
              ))}
            </Field>
          </li>
        )}
        {options.capture_title && (
          <li>
            <Field
              name="title"
              namespace="user"
              onChange={onUserChange}
              value={value("user", "title")}
            />
          </li>
        )}
        <DataFields
          changes={changes.data}
          onChange={onDataChange}
          original={original.data}
          registration={registration}
        />
        {registration && options.capture_package && (
          <PackageField setValue={setPackageID} value={packageID} />
        )}
      </ul>
      <ul>
        {registration && <RegistrationDisclaimer />}
        <li>
          <button className={pendingChanges ? "p-btn-blue" : "p-btn-blue p-btn-inactive"} type="submit">
            {i18n.t(registration ? "submit" : "save_changes")}
          </button>
        </li>
      </ul>
    </form>
  );
}

Form.defaultProps = {type: null};
Form.propTypes = {
  assignLocation: PropTypes.func.isRequired,
  createNotification: PropTypes.func.isRequired,
  current: PropTypes.shape({
    organization: PropTypes.shape({
      departments: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired
        }).isRequired
      ).isRequired,
      id: PropTypes.number.isRequired,
      options: PropTypes.shape({
        account_types: PropTypes.arrayOf(PropTypes.string).isRequired,
        capture_account_type: PropTypes.bool.isRequired,
        capture_birthdate: PropTypes.bool.isRequired,
        capture_department: PropTypes.bool.isRequired,
        capture_email: PropTypes.bool.isRequired,
        capture_external_id: PropTypes.bool.isRequired,
        capture_first_and_last_name: PropTypes.bool.isRequired,
        capture_major: PropTypes.bool.isRequired,
        capture_package: PropTypes.bool.isRequired,
        capture_password: PropTypes.bool.isRequired,
        capture_title: PropTypes.bool.isRequired,
        enable_sharing: PropTypes.bool.isRequired,
        require_complex_password: PropTypes.bool.isRequired
      }).isRequired
    }).isRequired
  }).isRequired,
  i18n: PropTypes.instanceOf(I18n).isRequired,
  locale: PropTypes.shape({code: PropTypes.string.isRequired}).isRequired,
  type: PropTypes.string,
  updateCurrent: PropTypes.func.isRequired
};

export default Form;
