import {useSelector} from "react-redux";
import {dig} from "common/lib/ext/object";

export default function useSurveyName() {
  const assessment = useSelector((state) => state.assessment);
  const locale = useSelector((state) => state.locale);
  const organization = useSelector((state) => state.current.organization);

  if(!assessment) { return null; }
  if(!locale) { return null; }
  if(!organization) { return null; }

  const assessmentType = organization.assessment_types
    .find((type) => type.deck_id === assessment.deck_id);

  if(!assessmentType) { return null; }

  const assessmentTypeI18n = assessmentType.i18ns
    .find((i18n) => i18n.locale_code === locale.rails_code);

  return dig(assessmentTypeI18n, "name") || assessmentType.name;
}
