import PropTypes from "prop-types";
import {Component} from "react";
import {last} from "common/lib/ext/array";
import {DangerousHTML} from "legacy/components/partials";

export default class Notifications extends Component {
  static propTypes = {
    deleteNotification: PropTypes.func.isRequired,
    notifications: PropTypes.arrayOf(
      PropTypes.shape({
        kind: PropTypes.string,
        id: PropTypes.string.isRequired,
        message: PropTypes.string.isRequired
      })
    ).isRequired
  };
  componentDidUpdate(props) {
    if(this.props.notifications.length > props.notifications.length) {
      window.location.hash = last(this.props.notifications).id;
    }
  }
  render() {
    const {deleteNotification, notifications} = this.props;

    return (
      <div>
        {notifications.map(({id, kind, message}) => (
          <div key={id} id={id} className={`alert alert--${kind || "success"}`}>
            <button title="close" className="close" onClick={() => deleteNotification(id)} type="button">x</button>
            <DangerousHTML html={message} />
          </div>
        ))}
      </div>
    );
  }
}
