import {useMemo} from "react";
import {useSelector} from "react-redux";
import I18n from "common/lib/i18n";

export default function useI18n() {
  const locale = useSelector((state) => state.locale);
  const organization = useSelector((state) => state.current.organization);

  return useMemo(() => new I18n(locale, organization), [locale, organization]);
}
