import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Components, useActive} from "traitify-widgets";
import {Loading} from "legacy/components/partials";
import {assignLocation} from "legacy/constants/history";
import traitify from "legacy/lib/traitify";
import Modal from "./modal";
import useSurveyEffect from "./use-survey-effect";

function Survey({redirect}) {
  const active = useActive();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const {completed, loading} = useSurveyEffect({redirect});

  useEffect(() => {
    if(!active) { return; }
    if(loading) { return; }

    showModal || setShowModal(true);
  }, [active?.id]);

  useEffect(() => {
    if(!completed) { return; }

    dispatch(assignLocation(redirect, true));
  }, [completed]);

  if(loading) { return <Loading />; }

  return (
    <div className="survey-container">
      {showModal && <Modal closeModal={() => setShowModal(false)} />}
      <Components.Survey.Container />
    </div>
  );
}

Survey.propTypes = {redirect: PropTypes.string.isRequired};

function SurveyContainer({assessmentID, benchmarkID, packageID, redirect, surveyType}) {
  const locale = useSelector((state) => state.locale);
  const user = useSelector((state) => state.current.user);

  useEffect(() => { traitify.updateLocale(locale.code); }, [locale.code]);

  const traitifyProps = traitify.props;
  const widgetProps = {
    ...traitifyProps,
    options: {
      ...traitifyProps.options,
      allowFullscreen: false,
      survey: {disableTimeLimit: true}
    },
    profileID: user.profile_uuid
  };

  if(assessmentID) { widgetProps.assessmentID = assessmentID; }
  if(benchmarkID) { widgetProps.benchmarkID = benchmarkID; }
  if(packageID) { widgetProps.packageID = packageID; }
  if(surveyType) { widgetProps.options.surveyType = surveyType.toLowerCase(); }

  return (
    <Components.Container {...widgetProps}>
      <Survey redirect={redirect} />
    </Components.Container>
  );
}

SurveyContainer.defaultProps = {
  assessmentID: null,
  benchmarkID: null,
  packageID: null,
  surveyType: null
};
SurveyContainer.propTypes = {
  assessmentID: PropTypes.string,
  benchmarkID: PropTypes.string,
  packageID: PropTypes.string,
  redirect: PropTypes.string.isRequired,
  surveyType: PropTypes.string
};

export default SurveyContainer;
