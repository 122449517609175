import {useEffect} from "react";

export default function usePageClass(pageClass) {
  useEffect(() => {
    if(!pageClass) { return; }

    const element = document.querySelector("body");
    element.classList.add(pageClass);

    return () => {
      element.classList.remove(pageClass);
    };
  }, [pageClass]);
}
