import {combineEpics, ofType} from "redux-observable";
import {from, of} from "rxjs";
import {catchError, filter, map, mergeMap} from "rxjs/operators";
import {i18nAxios} from "legacy/lib/axios";
import {assignLocation} from "legacy/constants/history";
import {createNotification} from "legacy/constants/notifications";
import {
  VERIFY_CODE,
  VERIFY_CODE_FAILED,
  VERIFY_CODE_FULFILLED,
  VERIFY_CODE_REJECTED,
  VERIFY_CODE_SUCCEEDED,
  verifyCodeFailed,
  verifyCodeFulfilled,
  verifyCodeRejected,
  verifyCodeSucceeded
} from "legacy/constants/sessions/verify-code";

const verifyCodeEpic = (action$, state$) => action$.pipe(
  ofType(VERIFY_CODE),
  mergeMap(({code}) => from(
    i18nAxios(state$.value.locale.code)
      .post("/api/user/session/verify_code", {account: {code}})
  ).pipe(
    map(verifyCodeFulfilled),
    catchError((error) => of(verifyCodeRejected(error.message)))
  ))
);

const verifyCodeFailedEpic = (action$) => action$.pipe(
  ofType(VERIFY_CODE_FAILED),
  filter(() => window.location.pathname === "/login"),
  map(({error}) => createNotification({kind: "error", message: error}))
);

const verifyCodeFulfilledEpic = (action$) => action$.pipe(
  ofType(VERIFY_CODE_FULFILLED),
  map(({response: {data}}) => (
    data.success
      ? verifyCodeSucceeded(data)
      : verifyCodeFailed(data.errors)
  ))
);

const verifyCodeRejectedEpic = (action$) => action$.pipe(
  ofType(VERIFY_CODE_REJECTED),
  map(({error}) => error),
  map(verifyCodeFailed)
);

const verifyCodeSucceededEpic = (action$) => action$.pipe(
  ofType(VERIFY_CODE_SUCCEEDED),
  map(({data: {redirect}}) => (
    assignLocation(
      redirect || window.location.pathname === "/login" ? "/choose-organization" : "/",
      true
    )
  ))
);

export default combineEpics(
  verifyCodeEpic,
  verifyCodeFailedEpic,
  verifyCodeFulfilledEpic,
  verifyCodeRejectedEpic,
  verifyCodeSucceededEpic
);
