import {connect} from "react-redux";
import I18n from "common/lib/i18n";
import {createNotification} from "legacy/constants/notifications";
import Component from "./presenter";

const mapStateToProps = ({
  current: {account, organization}, locale
}) => ({
  account,
  i18n: new I18n(locale, organization),
  locale
});

export default connect(
  mapStateToProps,
  {createNotification}
)(Component);
