import PropTypes from "prop-types";
import I18n from "common/lib/i18n";

function EmailLink({assessmentID, current: {organization}, i18n}) {
  const body = i18n.t("results.email_body", {
    personality_url: `${organization.url}/personality/${assessmentID}`,
    session_url: `${organization.url}/sessions/new`
  }).replace(/ /g, "%20");
  const subject = `Traitify ${i18n.t("results.personality_results")}`.replace(/ /g, "%20");
  const href = `mailto:?subject=${subject}&body=${body}`;
  const title = i18n.t("results.email");

  return (
    <div className="nav-item">
      <a className="nav-link" href={href} rel="noopener noreferrer" target="_blank" title={title}>
        <i alt={title} className="fa fa-envelope-o" />
      </a>
    </div>
  );
}

EmailLink.propTypes = {
  assessmentID: PropTypes.string.isRequired,
  current: PropTypes.shape({
    organization: PropTypes.shape({
      url: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  i18n: PropTypes.instanceOf(I18n).isRequired
};

export default EmailLink;
