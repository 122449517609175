import {useParams} from "react-router-dom";
import useQueryParams from "common/hooks/use-query-params";
import Survey from "legacy/components/partials/assessments/survey";
import useDidMount from "legacy/lib/hooks/use-did-mount";
import usePageClass from "legacy/lib/hooks/use-page-class";

const typeFrom = (type) => {
  if(type === "DIMENSION_BASED") { return "PERSONALITY"; }
  if(type === "TYPE_BASED") { return "PERSONALITY"; }

  return type || "PERSONALITY";
};

export default function Assessment() {
  const {assessmentID} = useParams();
  const surveyType = typeFrom(useQueryParams().get("survey_type"));
  const redirect = `/assessments/${assessmentID}/complete`;

  useDidMount(() => {
    window.google_conversion_id = 965414859;
    window.google_conversion_language = "en";
    window.google_conversion_format = "3";
    window.google_conversion_color = "ffffff";
    window.google_conversion_label = "TnCkCPuw0WMQy5-szAM";
    window.google_remarketing_only = false;
  });
  usePageClass("survey");

  return (
    <>
      <Survey assessmentID={assessmentID} surveyType={surveyType} redirect={redirect} />
      <script src="//www.googleadservices.com/pagead/conversion.js" type="text/javascript" />
    </>
  );
}
