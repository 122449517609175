import PropTypes from "prop-types";
import {isWhite} from "common/lib/ext/color";
import logoWhite from "public/assets/images/paradox/traitify-by-paradox-white.svg";

function Logo({current: {organization: {options}}}) {
  const isDefaultLogo = options.navbar_logo.url.includes("traitify-by-paradox");
  const isWhiteLogo = isDefaultLogo && options.navbar_color && !isWhite(options.navbar_color);
  const logo = isWhiteLogo ? logoWhite : options.navbar_logo.url;

  return <img alt="Traitify by Paradox" src={logo} />;
}

Logo.propTypes = {
  current: PropTypes.shape({
    organization: PropTypes.shape({
      options: PropTypes.shape({
        navbar_color: PropTypes.string,
        navbar_dropdown_color: PropTypes.string,
        navbar_logo: PropTypes.shape({url: PropTypes.string.isRequired}).isRequired
      }).isRequired
    }).isRequired
  }).isRequired
};

export default Logo;
