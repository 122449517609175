import {
  REQUEST_APP,
  REQUEST_APP_FAILED,
  REQUEST_APP_FULFILLED,
  REQUEST_APP_REJECTED,
  STORE_APP
} from "legacy/constants/app";

export default (
  state = {},
  action
) => {
  switch(action.type) {
    case REQUEST_APP:
      return {
        ...state,
        loading: true
      };
    case REQUEST_APP_FAILED:
      return {
        ...state,
        loaded: false
      };
    case REQUEST_APP_FULFILLED:
    case REQUEST_APP_REJECTED:
      return {
        ...state,
        loading: false
      };
    case STORE_APP:
      return {
        ...state,
        loaded: true
      };
    default:
      return {
        loaded: false,
        loading: false,
        ...state
      };
  }
};
