import LocaleData from "./locale-data.js.erb";

export default class Locale {
  static get locales() { return LocaleData; }
  static fromCode(code) {
    return this.locales.find((locale) => locale.code.toLowerCase() === code.toLowerCase());
  }
  static fromLanguage(_language) {
    const language = _language.toLowerCase();

    switch(language) {
      case "en":
        return this.fromCode("en-US");
      case "es":
        return this.fromCode("es-US");
      case "fr":
        // TODO: Someday - Remove Canada as the default French
        return this.fromCode("fr-CA");
      default:
        return this.locales.find(({code}) => code.split("-")[0] === language);
    }
  }
  static fromRailsCode(code) {
    return this.locales.find((locale) => locale.rails_code.toLowerCase() === code.toLowerCase());
  }
  static fromUser(code) {
    return this.fromCode(code) || this.fromRailsCode(code) || this.fromLanguage(code.split("-")[0]);
  }
  static isCode(code) {
    return !!this.fromCode(code);
  }
}
