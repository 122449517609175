import PropTypes from "prop-types";
import {useEffect} from "react";
import {useParams} from "react-router-dom";
import {Components} from "traitify-widgets";
import {dig} from "common/lib/ext/object";
import useQueryParams from "common/hooks/use-query-params";
import {Loading, LocalePicker} from "legacy/components/partials";
import {displayInitialsFrom, displayNameFrom} from "legacy/constants/current";
import useDisabledComponents from "legacy/lib/hooks/assessment/use-disabled-components";
import usePageClass from "legacy/lib/hooks/use-page-class";
import traitify from "legacy/lib/traitify";
import CognitiveSection from "./cognitive-section";

const allReports = [
  {name: "Big Five Hiring Manager Report", perspective: "thirdPerson", view: "manager"},
  {name: "Big Five Candidate Report", perspective: "thirdPerson", view: "candidate"},
  {name: "Engage Employee Report", perspective: "thirdPerson", view: "employee"}
];

function ShowAdmin({
  assessment,
  loadAssessment,
  locale,
  organization: {locales, options},
  profile,
  recommendation,
  report,
  storeProfile,
  updateCurrentRecommendation,
  updateCurrentReport
}) {
  const {adminToken, assessmentID} = useParams();
  const benchmarkID = useQueryParams().get("benchmark_id") || dig(recommendation, "recommendation_id");
  const disabledComponents = useDisabledComponents({admin: true});
  const disabledReports = [
    !options.show_candidate_report && "candidate",
    !options.show_employee_report && "employee"
  ].filter(Boolean);
  const reports = allReports.filter(({view}) => !disabledReports.includes(view));

  usePageClass("show-admin");
  useEffect(() => { traitify.updateLocale(locale.code); }, [locale.code]);
  useEffect(() => { loadAssessment(assessmentID, {surveyType: "PERSONALITY"}); }, [assessmentID]);
  useEffect(() => {
    if(!assessment) { return; }

    traitify
      .http.get(`/profiles/${assessment.profile_ids[0]}?admin_token=${adminToken}`)
      .then(storeProfile);
  }, [adminToken, assessment]);

  useEffect(() => {
    const updateBenchmark = (state) => updateCurrentRecommendation(state.recommendation);

    return traitify.listener.on("RecommendationList.updated", updateBenchmark);
  }, []);

  useEffect(() => {
    if(!report) { updateCurrentReport(reports[0]); }
  }, [assessment]);

  if(!assessment || !profile || !report) { return <Loading />; }

  const traitifyProps = traitify.props;
  const widgetProps = {
    ...traitifyProps,
    assessmentID: assessment.id,
    locale: locale.code,
    options: {
      ...traitifyProps.options,
      disabledComponents,
      perspective: "thirdPerson",
      report: report.view,
      results: {benchmarkID},
      showHeaders: true,
      showRecommendationList: true
    }
  };

  if(assessment.assessment_type !== "DIMENSION_BASED") {
    return (
      <Components.Container {...widgetProps}>
        <Components.Results.Container />
      </Components.Container>
    );
  }

  const onReportChange = ({target: {value}}) => {
    updateCurrentReport(reports.find(({name}) => name === value));
  };

  return (
    <div className={`show-results ${report.view}-results`}>
      <Components.Container {...widgetProps}>
        <section className="md-flex md-items-center md-justify-between p-box mb-section">
          <div className="md-mb-0 mb-buffer flex items-center">
            <div className="inline-flex mr-buffer p-heading p-profile-circle">{displayInitialsFrom(profile)}</div>
            <div className="inline-block p-heading">{displayNameFrom(profile)}</div>
          </div>
          <div className="flex">
            <label htmlFor="report-name">
              <span className="p-heading">Report View</span>
              <select className="p-form-field mt-buffer-sm" id="report-name" name="report" value={report.name} onChange={onReportChange}>
                {reports.map(({name}) => <option key={name} value={name}>{name}</option>)}
              </select>
            </label>
            {locales.length > 1 && options.enable_admin_results_locale && (
              <label className="ml-buffer" htmlFor="locale">
                <span className="p-heading">Locale</span>
                <LocalePicker className="mt-buffer-sm" />
              </label>
            )}
          </div>
        </section>
        <Components.Results.Container />
        <CognitiveSection />
      </Components.Container>
    </div>
  );
}

ShowAdmin.defaultProps = {assessment: null, profile: null, report: null, recommendation: null};
ShowAdmin.propTypes = {
  assessment: PropTypes.shape({
    assessment_type: PropTypes.string.isRequired,
    deck_id: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    profile_ids: PropTypes.arrayOf(PropTypes.string).isRequired
  }),
  loadAssessment: PropTypes.func.isRequired,
  locale: PropTypes.shape({
    code: PropTypes.string.isRequired,
    rails_code: PropTypes.string.isRequired
  }).isRequired,
  organization: PropTypes.shape({
    locales: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string.isRequired
      }).isRequired
    ).isRequired,
    options: PropTypes.shape({
      enable_admin_results_locale: PropTypes.bool.isRequired,
      show_big_five_archetype: PropTypes.bool.isRequired,
      show_big_five_caution: PropTypes.bool.isRequired,
      show_big_five_dimension_columns: PropTypes.bool.isRequired,
      show_big_five_dimension_details: PropTypes.bool.isRequired,
      show_big_five_settings: PropTypes.bool.isRequired,
      show_big_five_skills: PropTypes.bool.isRequired,
      show_big_five_tools: PropTypes.bool.isRequired,
      show_candidate_report: PropTypes.bool.isRequired,
      show_employee_report: PropTypes.bool.isRequired
    }).isRequired
  }).isRequired,
  profile: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string
  }),
  recommendation: PropTypes.shape({recommendation_id: PropTypes.string.isRequired}),
  report: PropTypes.shape({
    name: PropTypes.string.isRequired,
    perspective: PropTypes.string.isRequired,
    view: PropTypes.string.isRequired
  }),
  storeProfile: PropTypes.func.isRequired,
  updateCurrentRecommendation: PropTypes.func.isRequired,
  updateCurrentReport: PropTypes.func.isRequired
};

export default ShowAdmin;
