import {STORE_APP} from "legacy/constants/app";
import {UPDATE_LOCALE} from "legacy/constants/locale";

export default (
  state = {},
  action
) => {
  switch(action.type) {
    case STORE_APP:
      return {
        ...state,
        ...action.app.locale
      };
    case UPDATE_LOCALE:
      return {...action.locale};
    default:
      return state;
  }
};
