import {useEffect} from "react";

// NOTE: Reloads page to ensure correct JS is loaded
export default function Reload() {
  useEffect(() => {
    window.location.reload();
  }, []);

  return null;
}
