import {connect} from "react-redux";
import I18n from "common/lib/i18n";
import Component from "./presenter";

const mapStateToProps = ({
  current, locale
}) => ({
  current, i18n: new I18n(locale, current.organization)
});

export default connect(mapStateToProps)(Component);
