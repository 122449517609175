import PropTypes from "prop-types";
import {Component} from "react";
import I18n from "common/lib/i18n";

export default class AllowRetake extends Component {
  static propTypes = {
    assessment: PropTypes.shape({
      deck_id: PropTypes.string.isRequired
    }).isRequired,
    i18n: PropTypes.instanceOf(I18n).isRequired,
    organization: PropTypes.shape({
      options: PropTypes.shape({
        allow_retake: PropTypes.bool.isRequired
      }).isRequired
    }).isRequired
  };
  render() {
    const {assessment, i18n, organization} = this.props;
    if(!organization.options.allow_retake) { return null; }

    return (
      <section className="retake">
        <h3 className="heading">
          <i className="fa fa-refresh" />
          {i18n.t("results.personality_reset")}
        </h3>
        <p>{i18n.t("results.mistake")}</p>
        <p>
          <a href={`/assessment?r=etry&deck_id=${assessment.deck_id}`} className="btn--red" title={i18n.t("results.reset_personality")}>{i18n.t("results.reset_personality")}</a>
        </p>
      </section>
    );
  }
}
