import {STORE_ASSESSMENT} from "legacy/constants/assessment";

export default (
  state = null,
  action
) => {
  switch(action.type) {
    case STORE_ASSESSMENT:
      return action.assessment;
    default:
      return state;
  }
};
