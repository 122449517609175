import PropTypes from "prop-types";
import I18n from "common/lib/i18n";
import Locale from "common/lib/locale";
import LocalePicker from "legacy/components/partials/locale-picker";
import useDidMount from "legacy/lib/hooks/use-did-mount";
import useDidUpdate from "legacy/lib/hooks/use-did-update";

function LocaleField({
  className,
  current: {organization: {locales}},
  i18n,
  locale: currentLocale,
  onChange,
  original: {profile},
  registration,
  updateLocale
}) {
  const onLocaleChange = ({target: {value}}) => {
    onChange({target: {name: "primary_language", value}});
  };

  useDidMount(() => {
    const code = profile.primary_language;
    const locale = code && Locale.fromUser(code);

    if(!locale) { return onLocaleChange({target: {value: currentLocale.code}}); }
    if(code !== locale.code) { onLocaleChange({target: {value: locale.code}}); }
    if(currentLocale.code !== locale.code) { updateLocale(locale); }
  });

  useDidUpdate(() => {
    const code = profile.primary_language;
    const locale = code && Locale.fromUser(code);

    if(!locale || currentLocale.code !== locale.code) {
      onLocaleChange({target: {value: currentLocale.code}});
    }
  }, [currentLocale]);

  if(locales.length === 0) { return null; }

  return (
    <li>
      {!registration && (
        <label className="label" htmlFor="locale">{i18n.t("attributes.language")}</label>
      )}
      <LocalePicker className={className} onChange={onLocaleChange} />
    </li>
  );
}

LocaleField.defaultProps = {className: null};
LocaleField.propTypes = {
  className: PropTypes.string,
  current: PropTypes.shape({
    organization: PropTypes.shape({
      locales: PropTypes.arrayOf(
        PropTypes.shape({
          code: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired
        }).isRequired
      ).isRequired
    }).isRequired
  }).isRequired,
  i18n: PropTypes.instanceOf(I18n).isRequired,
  locale: PropTypes.shape({
    code: PropTypes.string.isRequired
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  original: PropTypes.shape({
    profile: PropTypes.shape({
      primary_language: PropTypes.string
    }).isRequired
  }).isRequired,
  registration: PropTypes.bool.isRequired,
  updateLocale: PropTypes.func.isRequired
};

export default LocaleField;
