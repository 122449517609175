export const LOAD_ASSESSMENT = "LOAD_ASSESSMENT";
export const RELOAD_ASSESSMENT = "RELOAD_ASSESSMENT";
export const REQUEST_ASSESSMENT = "REQUEST_ASSESSMENT";
export const REQUEST_ASSESSMENT_FAILED = "REQUEST_ASSESSMENT_FAILED";
export const REQUEST_ASSESSMENT_FULFILLED = "REQUEST_ASSESSMENT_FULFILLED";
export const REQUEST_ASSESSMENT_REJECTED = "REQUEST_ASSESSMENT_REJECTED";
export const REQUEST_ASSESSMENT_SUCCEEDED = "REQUEST_ASSESSMENT_SUCCEEDED";
export const SHARE_ASSESSMENT = "SHARE_ASSESSMENT";
export const STORE_ASSESSMENT = "STORE_ASSESSMENT";

export const loadAssessment = (assessmentID, options = {}) => ({
  type: LOAD_ASSESSMENT, assessmentID, ...options
});
export const reloadAssessment = () => ({type: RELOAD_ASSESSMENT});
export const requestAssessment = (assessmentID, options = {}) => ({
  type: REQUEST_ASSESSMENT, assessmentID, ...options
});
export const requestAssessmentFailed = (error) => ({type: REQUEST_ASSESSMENT_FAILED, error});
export const requestAssessmentFulfilled = (data) => ({
  type: REQUEST_ASSESSMENT_FULFILLED, data
});
export const requestAssessmentRejected = (error) => ({type: REQUEST_ASSESSMENT_REJECTED, error});
export const requestAssessmentSucceeded = (assessment) => ({
  type: REQUEST_ASSESSMENT_SUCCEEDED, assessment
});
export const shareAssessment = (provider) => ({type: SHARE_ASSESSMENT, provider});
export const storeAssessment = (assessment) => ({type: STORE_ASSESSMENT, assessment});
