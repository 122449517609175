import PropTypes from "prop-types";
import {Components} from "traitify-widgets";
import {mutable} from "common/lib/ext/object";
import {Assessments} from "legacy/components/partials";
import AllowRetake from "./allow-retake";
import Heading from "./heading";

function Report({mine, widgetProps: _widgetProps}) {
  const widgetProps = mutable(_widgetProps);
  widgetProps.options.showHeaders = true;

  return (
    <div className={`show-results ${widgetProps.options.report}-results`}>
      <Components.Container {...widgetProps}>
        <Assessments.Picker />
        <Heading />
        <Components.Results.Container />
        {mine && <AllowRetake />}
      </Components.Container>
    </div>
  );
}

Report.propTypes = {
  mine: PropTypes.bool.isRequired,
  widgetProps: PropTypes.shape({
    options: PropTypes.shape({
      report: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default Report;
