import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import useQueryParams from "common/hooks/use-query-params";
import Partial from "legacy/components/partials/assessments/thanks";
import {loadAssessment} from "legacy/constants/assessment";

const typeFrom = (type) => {
  if(type === "DIMENSION_BASED") { return "PERSONALITY"; }
  if(type === "TYPE_BASED") { return "PERSONALITY"; }

  return type || "PERSONALITY";
};

export default function Thanks() {
  const dispatch = useDispatch();
  const {assessmentID} = useParams();
  const surveyType = typeFrom(useQueryParams().get("survey_type"));

  useEffect(() => { dispatch(loadAssessment(assessmentID, {surveyType})); }, [assessmentID]);

  return <Partial assessmentID={assessmentID} />;
}
