import {
  CREATE_PASSWORD_RESET_FAILED,
  CREATE_PASSWORD_RESET_SUCCEEDED
} from "legacy/constants/passwords";
import {
  CREATE_SESSION,
  CREATE_SESSION_2FA,
  CREATE_SESSION_FULFILLED,
  CREATE_SESSION_MESSAGE,
  CREATE_SESSION_REJECTED,
  CREATE_SESSION_FAILED
} from "legacy/constants/sessions";
import {
  RESEND_CODE_FAILED,
  RESEND_CODE_SUCCEEDED
} from "legacy/constants/sessions/resend-code";
import {
  VERIFY_CODE_FAILED,
  VERIFY_CODE_SUCCEEDED
} from "legacy/constants/sessions/verify-code";

const defaultState = {
  forgotPassword: {},
  loading: false,
  require2fa: false,
  tfa: {}
};

export default (
  state = defaultState,
  action
) => {
  switch(action.type) {
    case CREATE_PASSWORD_RESET_FAILED:
      return {
        ...state,
        forgotPassword: {errors: action.errors}
      };
    case CREATE_PASSWORD_RESET_SUCCEEDED:
      return {
        ...state,
        forgotPassword: {success: true}
      };
    case CREATE_SESSION:
      return {
        ...state,
        loading: true,
        require2fa: false
      };
    case CREATE_SESSION_FAILED:
      return {
        ...state,
        error: action.error,
        message: null
      };
    case CREATE_SESSION_MESSAGE:
      return {
        ...state,
        error: null,
        message: action.message
      };
    case CREATE_SESSION_FULFILLED:
    case CREATE_SESSION_REJECTED:
      return {
        ...state,
        loading: false
      };
    case CREATE_SESSION_2FA:
      return {
        ...state,
        require2fa: true
      };
    case RESEND_CODE_FAILED:
    case VERIFY_CODE_FAILED:
      return {
        ...state,
        tfa: {error: action.error}
      };
    case RESEND_CODE_SUCCEEDED:
    case VERIFY_CODE_SUCCEEDED:
      return {
        ...state,
        tfa: {success: true}
      };
    default:
      return state;
  }
};
