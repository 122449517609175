import {STORE_PROFILE} from "legacy/constants/profile";

export default (
  state = null,
  action
) => {
  switch(action.type) {
    case STORE_PROFILE:
      return action.profile;
    default:
      return state;
  }
};
