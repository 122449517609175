export const ADD_DEPENDENCIES = "ADD_DEPENDENCIES";
export const DEPENDENCY_ERROR = "DEPENDENCY_ERROR";
export const DEPENDENCY_LOADED = "DEPENDENCY_LOADED";
export const DEPENDENCY_LOADING = "DEPENDENCY_LOADING";
export const LOAD_APP = "LOAD_APP";
export const LOAD_DEPENDENCIES = "LOAD_DEPENDENCIES";
export const LOAD_DEPENDENCY = "LOAD_DEPENDENCY";
export const LOADED_DEPENDENCY = "LOADED_DEPENDENCY";
export const REQUEST_APP = "REQUEST_APP";
export const REQUEST_APP_FAILED = "REQUEST_APP_FAILED";
export const REQUEST_APP_FULFILLED = "REQUEST_APP_FULFILLED";
export const REQUEST_APP_REJECTED = "REQUEST_APP_REJECTED";
export const REQUEST_APP_SUCCEEDED = "REQUEST_APP_SUCCEEDED";
export const STORE_APP = "STORE_APP";

export const addDependencies = (dependencies) => ({type: ADD_DEPENDENCIES, dependencies});
export const dependencyError = (dependency, error) => ({
  type: DEPENDENCY_ERROR, dependency, error
});
export const dependencyLoaded = (dependency) => ({type: DEPENDENCY_LOADED, dependency});
export const dependencyLoading = (dependency) => ({type: DEPENDENCY_LOADING, dependency});
export const loadApp = () => ({type: LOAD_APP});
export const loadDependencies = () => ({type: LOAD_DEPENDENCIES});
export const loadDependency = (dependency) => ({type: LOAD_DEPENDENCY, dependency});
export const loadedDependency = (dependency) => ({type: LOADED_DEPENDENCY, dependency});
export const requestApp = () => ({type: REQUEST_APP});
export const requestAppFailed = (error) => ({type: REQUEST_APP_FAILED, error});
export const requestAppFulfilled = (response) => ({type: REQUEST_APP_FULFILLED, response});
export const requestAppRejected = (error) => ({type: REQUEST_APP_REJECTED, error});
export const requestAppSucceeded = (app) => ({type: REQUEST_APP_SUCCEEDED, app});
export const storeApp = (app) => ({type: STORE_APP, app});
