import {useEffect, useState} from "react";
import {useAssessments} from "traitify-widgets";

export default function useInitialAssessments() {
  const assessments = useAssessments();
  const [initialAssessments, setInitialAssessments] = useState(null);

  useEffect(() => {
    if(!assessments) { return; }
    if(initialAssessments) { return; }

    setInitialAssessments(assessments);
  }, [assessments]);

  return initialAssessments;
}
