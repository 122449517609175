import {combineEpics, ofType} from "redux-observable";
import {from, of} from "rxjs";
import {catchError, filter, map, mergeMap} from "rxjs/operators";
import {assignLocation} from "legacy/constants/history";
import {createNotification} from "legacy/constants/notifications";
import {
  CREATE_PASSWORD_RESET,
  CREATE_PASSWORD_RESET_FAILED,
  CREATE_PASSWORD_RESET_FULFILLED,
  CREATE_PASSWORD_RESET_REJECTED,
  CREATE_PASSWORD_RESET_SUCCEEDED,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_FAILED,
  UPDATE_PASSWORD_FULFILLED,
  UPDATE_PASSWORD_REJECTED,
  UPDATE_PASSWORD_SUCCEEDED,
  createPasswordResetFailed,
  createPasswordResetFulfilled,
  createPasswordResetRejected,
  createPasswordResetSucceeded,
  updatePasswordFailed,
  updatePasswordFulfilled,
  updatePasswordRejected,
  updatePasswordSucceeded
} from "legacy/constants/passwords";
import {i18nAxios} from "legacy/lib/axios";

const createPasswordResetEpic = (action$, state$) => action$.pipe(
  ofType(CREATE_PASSWORD_RESET),
  mergeMap(({params}) => from(i18nAxios(state$.value.locale.code).post("/api/user/password/send_reset_email", params)).pipe(
    map(createPasswordResetFulfilled),
    catchError((error) => of(createPasswordResetRejected(error.message)))
  ))
);

const createPasswordResetFailedEpic = (action$) => action$.pipe(
  ofType(CREATE_PASSWORD_RESET_FAILED),
  filter(() => window.location.pathname === "/login"),
  map(({errors}) => createNotification({kind: "error", message: errors}))
);

const createPasswordResetFulfilledEpic = (action$) => action$.pipe(
  ofType(CREATE_PASSWORD_RESET_FULFILLED),
  map(({response: {data: {errors, message, success}}}) => (
    success
      ? createPasswordResetSucceeded(message)
      : createPasswordResetFailed(errors)
  ))
);

const createPasswordResetRejectedEpic = (action$) => action$.pipe(
  ofType(CREATE_PASSWORD_RESET_REJECTED),
  map(({errors}) => errors),
  map(createPasswordResetFailed)
);

const createPasswordResetSucceededEpic = (action$) => action$.pipe(
  ofType(CREATE_PASSWORD_RESET_SUCCEEDED),
  map(({message}) => ({kind: "success", message})),
  map(createNotification)
);

const updatePasswordEpic = (action$, state$) => action$.pipe(
  ofType(UPDATE_PASSWORD),
  mergeMap(({params}) => from(i18nAxios(state$.value.locale.code).put("/api/user/password", params)).pipe(
    map(updatePasswordFulfilled),
    catchError((error) => of(updatePasswordRejected(error.message)))
  ))
);

const updatePasswordFailedEpic = (action$) => action$.pipe(
  ofType(UPDATE_PASSWORD_FAILED),
  map(({errors}) => createNotification({kind: "error", message: errors}))
);

const updatePasswordFulfilledEpic = (action$) => action$.pipe(
  ofType(UPDATE_PASSWORD_FULFILLED),
  map(({response: {data: {errors, redirect, success}}}) => (
    success
      ? updatePasswordSucceeded({redirect})
      : updatePasswordFailed(errors)
  ))
);

const updatePasswordRejectedEpic = (action$) => action$.pipe(
  ofType(UPDATE_PASSWORD_REJECTED),
  map(({errors}) => errors),
  map(updatePasswordFailed)
);

const updatePasswordSucceededEpic = (action$) => action$.pipe(
  ofType(UPDATE_PASSWORD_SUCCEEDED),
  map(({redirect}) => assignLocation(redirect, true))
);

export default combineEpics(
  createPasswordResetEpic,
  createPasswordResetFailedEpic,
  createPasswordResetFulfilledEpic,
  createPasswordResetRejectedEpic,
  createPasswordResetSucceededEpic,
  updatePasswordEpic,
  updatePasswordFailedEpic,
  updatePasswordFulfilledEpic,
  updatePasswordRejectedEpic,
  updatePasswordSucceededEpic
);
