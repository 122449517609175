import PropTypes from "prop-types";
import {Component} from "react";

export default class Loading extends Component {
  static propTypes = {
    icon: PropTypes.string
  };
  static defaultProps = {icon: "spinner"};
  render() {
    const {icon} = this.props;

    return (
      <div className="center">
        <i className={`fa fa-${icon} fa-spin fa-2x`} />
      </div>
    );
  }
}
