import {STORE_ASSESSMENTS} from "legacy/constants/assessments";

export default (
  state = [],
  action
) => {
  switch(action.type) {
    case STORE_ASSESSMENTS:
      return action.assessments;
    default:
      return state;
  }
};
