import PropTypes from "prop-types";
import {Components} from "traitify-widgets";
import {mutable} from "common/lib/ext/object";
import {Assessments} from "legacy/components/partials";
import AllowRetake from "../allow-retake";
import Utilities from "../utilities";

function FinancialRisk({mine, widgetProps: _widgetProps}) {
  const widgetProps = mutable(_widgetProps);
  widgetProps.options.showHeaders = true;

  return (
    <div className="show-results">
      <Components.Container {...widgetProps}>
        <Assessments.Picker />
        <Utilities />
        <Components.Results.FinancialRisk />
        {mine && <AllowRetake />}
      </Components.Container>
    </div>
  );
}

FinancialRisk.propTypes = {
  mine: PropTypes.bool.isRequired,
  widgetProps: PropTypes.shape({
    assessmentID: PropTypes.string.isRequired
  }).isRequired
};

export default FinancialRisk;
