import PropTypes from "prop-types";
import {useState} from "react";
import I18n from "common/lib/i18n";
import {displayInitials} from "legacy/constants/current";
import AccountLinks from "./account-links";
import AdminLinks from "./admin-links";
import Logo from "./logo";
import SessionLinks from "./session-links";
import Theme from "./theme";

function Navbar({assessments, current, i18n, updateLocale}) {
  const {account, profile} = current;
  const [show, setShow] = useState(false);

  const isAdminResults = window.location.pathname.startsWith("/admin");
  const isSessions = ["/login", "/sessions/new"].includes(window.location.pathname)
    || window.location.pathname === "/";

  return (
    <header className="header">
      <div className="flex flex-wrap md-flex-nowrap items-center">
        <div className="flex-grow">
          <a aria-label="Logo" className="logo" href="/">
            <Logo current={current} />
          </a>
        </div>
        <a className="skipLink" href="#main" title="Skip Navigation">
          Skip Navigation
        </a>
        <button className="nav-toggle md-hidden" onClick={() => setShow(!show)} type="button">
          {(account || !isSessions) ? (
            <>{displayInitials({account, profile}) || <i className="fa fa-user fa-lg" />} <i className="fa fa-angle-down fa-lg ml-1" /></>
          ) : (
            <i className="fa fa-bars fa-lg" />
          )}
        </button>
        <nav aria-label="Main Menu" className={`${show ? "flex" : "hidden"} basis-full md-basis-auto md-flex gap-2`}>
          {!account && <SessionLinks current={current} i18n={i18n} updateLocale={updateLocale} />}
          {account && (
            <AccountLinks
              assessments={assessments}
              current={current}
              i18n={i18n}
              isSessions={isSessions}
            />
          )}
          {isAdminResults && <AdminLinks current={current} i18n={i18n} />}
        </nav>
      </div>
      <Theme current={current} />
    </header>
  );
}

Navbar.propTypes = {
  assessments: PropTypes.arrayOf(
    PropTypes.shape({completed_at: PropTypes.number}).isRequired
  ).isRequired,
  current: PropTypes.shape({
    account: PropTypes.shape({name: PropTypes.string}),
    profile: PropTypes.shape({name: PropTypes.string})
  }).isRequired,
  i18n: PropTypes.instanceOf(I18n).isRequired,
  updateLocale: PropTypes.func.isRequired
};

export default Navbar;
