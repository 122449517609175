import PropTypes from "prop-types";

function Theme({current: {organization: {options}}}) {
  const style = options.navbar_color && `
    footer.footer { background-color: #${options.navbar_color} !important; }
    header.header { background-color: #${options.navbar_color} !important; }
    header nav select { color: ${options.navbar_hover_color} !important; }
    header .nav-toggle {
      background: none !important;
      border-radius: 0 !important;
    }
    header .nav-dropdown {
      border: 1px solid #${options.navbar_color};
    }
    header .nav-link {
      background-color: white !important;
      border-color: #${options.navbar_color} !important;
      color: #${options.navbar_color} !important;
    }
    header .nav-item {
      border-color: #${options.navbar_color} !important;
    }
    header .nav-link:focus,
    header .nav-link:hover {
      background-color: ${options.navbar_hover_color} !important;
      color: white !important;
    }
    .border-theme { border-color: ${options.navbar_hover_color} !important; }
    .text-theme { color: ${options.navbar_hover_color} !important; }
    .footer { background: #${options.navbar_color} !important; }
  `;

  return style && <style>{style}</style>;
}

Theme.propTypes = {
  current: PropTypes.shape({
    organization: PropTypes.shape({
      options: PropTypes.shape({
        capture_email: PropTypes.bool,
        capture_external_id: PropTypes.bool,
        capture_password: PropTypes.bool,
        navbar_color: PropTypes.string,
        navbar_hover_color: PropTypes.string,
        navbar_logo: PropTypes.shape({url: PropTypes.string.isRequired}).isRequired,
        show_admin_login: PropTypes.bool,
        show_contact_us_in_dropdown: PropTypes.bool,
        show_results: PropTypes.bool,
        show_settings_in_dropdown: PropTypes.bool
      }).isRequired
    }).isRequired
  }).isRequired
};

export default Theme;
