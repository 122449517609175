import {connect} from "react-redux";
import {deleteNotification} from "legacy/constants/notifications";
import Component from "./presenter";

const mapStateToProps = ({notifications}) => ({notifications});

export default connect(
  mapStateToProps,
  {deleteNotification}
)(Component);
