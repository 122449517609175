import PropTypes from "prop-types";
import I18n from "common/lib/i18n";
import {DangerousHTML} from "legacy/components/partials";
import Markdown from "legacy/components/partials/markdown";

function RegistrationDisclaimer({i18n}) {
  const text = i18n.tOption("registration_disclaimer");

  return (
    <li className="form__disclaimer clear text-left">
      {text ? (
        <Markdown className="form-section">{text}</Markdown>
      ) : (
        <DangerousHTML
          html={i18n.t("sessions.tos_html", {
            button: i18n.t("submit"),
            terms_of_service: `<a href="https://www.traitify.com/legal" target="_blank">${i18n.t("terms_of_service")}</a>`,
            privacy_policy: `<a href="https://www.traitify.com/legal#privacy-policy" target="_blank">${i18n.t("privacy_policy")}</a>`
          })}
          tag="p"
        />
      )}
    </li>
  );
}

RegistrationDisclaimer.propTypes = {
  i18n: PropTypes.instanceOf(I18n).isRequired
};

export default RegistrationDisclaimer;
