import PropTypes from "prop-types";
import {useMatch} from "react-router-dom";
import I18n from "common/lib/i18n";
import EmailLink from "./email-link";
import PdfLink from "./pdf-link";

function AdminLinks({current, i18n}) {
  const match = useMatch("/admin/personality/:assessmentID/:adminToken") || {params: {}};
  const {adminToken, assessmentID} = match.params;

  return (
    <>
      <EmailLink assessmentID={assessmentID} current={current} i18n={i18n} />
      <PdfLink adminToken={adminToken} assessmentID={assessmentID} current={current} i18n={i18n} />
    </>
  );
}

AdminLinks.propTypes = {
  current: PropTypes.shape({
    organization: PropTypes.shape({
      url: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  i18n: PropTypes.instanceOf(I18n).isRequired
};

export default AdminLinks;
