import PropTypes from "prop-types";
import {useState} from "react";
import {useParams} from "react-router-dom";
import I18n from "common/lib/i18n";
import PasswordField from "legacy/components/partials/account/form/password-field";

function Edit({i18n, organization: {options}, updatePassword}) {
  const [account, setAccount] = useState({password: "", password_confirmation: ""});
  const {token} = useParams();
  const onChange = ({target: {name, value}}) => setAccount({...account, [name]: value});
  const onSubmit = (e) => {
    e.preventDefault();

    updatePassword({account, token});
  };

  return (
    <section className="edit-passwords">
      <form className="form-default" onSubmit={onSubmit}>
        <ul>
          <PasswordField
            complex={options.require_complex_password}
            onChange={onChange}
            password={account.password}
            passwordConfirmation={account.password_confirmation}
          />
          <li className="form--center">
            <button className="btn--primary" type="submit">{i18n.t("submit")}</button>
          </li>
        </ul>
      </form>
    </section>
  );
}

Edit.propTypes = {
  i18n: PropTypes.instanceOf(I18n).isRequired,
  organization: PropTypes.shape({
    options: PropTypes.shape({require_complex_password: PropTypes.bool.isRequired}).isRequired
  }).isRequired,
  updatePassword: PropTypes.func.isRequired
};

export default Edit;
