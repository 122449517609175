import PropTypes from "prop-types";
import I18n from "common/lib/i18n";

function LocalePicker({className, i18n, locale, onChange, organization, updateLocale}) {
  const onLocaleChange = ({target: {name, value}}) => {
    const newLocale = organization.locales.find(({code}) => code === value);

    onChange && onChange({target: {name, value}});
    newLocale && updateLocale(newLocale);
  };

  const classes = [className, "p-form-field"].filter(Boolean).join(" ");

  return (
    <select className={classes} name="locale" onChange={onLocaleChange} value={locale.code}>
      <option value="">{i18n.t("attributes.language")}</option>
      {organization.locales.map(({code, name}) => (
        <option key={code} value={code}>{name}</option>
      ))}
    </select>
  );
}

LocalePicker.defaultProps = {className: null, onChange: null};
LocalePicker.propTypes = {
  className: PropTypes.string,
  organization: PropTypes.shape({
    locales: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
      }).isRequired
    )
  }).isRequired,
  i18n: PropTypes.instanceOf(I18n).isRequired,
  locale: PropTypes.shape({
    code: PropTypes.string.isRequired
  }).isRequired,
  onChange: PropTypes.func,
  updateLocale: PropTypes.func.isRequired
};

export default LocalePicker;
