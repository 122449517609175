import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import I18n from "common/lib/i18n";
import useQueryParams from "common/hooks/use-query-params";
import * as Account from "legacy/components/partials/account";
import usePageClass from "legacy/lib/hooks/use-page-class";
import videoThumbnail from "public/assets/images/session/video-thumbnail.png";
import VideoModal from "./modals/video";

function New({
  account,
  createNotification,
  i18n,
  notifications,
  organization,
  session
}) {
  const iframe = useQueryParams().get("lt") === "iframe";
  const {options} = organization;
  const shibbolethURL = options.shibboleth && [
    `${session.shibboleth}/Shibboleth.sso/Login?entityID=${options.shibboleth}`,
    `&target=${session.shibboleth}/auth/shibboleth/callback?subdomain=${organization.subdomain}`
  ].join("");
  const showIntroVideo = options.show_registration_video;
  const [showVideoModal, setShowVideoModal] = useState(false);

  usePageClass("new-sessions");
  useEffect(() => {
    let image = `url(${organization.background.url})`;
    if(organization.darken_background) {
      image = `linear-gradient(to right, rgba(0,0,0, 0.6), rgba(0,0,0, 0.6)), ${image}`;
    }

    const element = document.querySelector("#main");
    element.style.backgroundImage = image;
    element.style.backgroundSize = "cover";

    return () => {
      element.style.backgroundImage = null;
      element.style.backgroundSize = null;
    };
  }, [organization]);

  useEffect(() => {
    if(!session.redirect) { return; }
    if(notifications.length > 0) { return; }

    createNotification({kind: "error", message: i18n.t("sessions.signin_first")});
  }, [session.redirect]);

  useEffect(() => {
    if(!iframe) { return; }
    if(document.cookie.length > 0) { return; }
    if(window.top.location !== window.location) { return; }

    window.top.location = window.location.href.replace("lt=iframe", "");
  }, [iframe]);

  return (
    <div>
      <section className="signup-container">
        <div className="signup">
          <h2>{i18n.t(account ? "sessions.complete_registration" : "get_started")}</h2>
          {!account && (
            <div className="pb-buffer">{i18n.t(options.registration_text ? options.registration_text : "sessions.registration_text")}</div>
          )}
          {shibbolethURL ? (
            <p><a href={shibbolethURL} className="p-btn-blue">Login</a></p>
          ) : <Account.Form type="Registration" />}

          {showIntroVideo && options.video_url && (
            <>
              <hr className="divider" />
              <div className="video-button-container">
                <button className="p-btn" type="button" onClick={() => { setShowVideoModal(true); }}>
                  <img src={videoThumbnail} alt="Registration Video" />
                </button>
              </div>
              <VideoModal
                onClose={() => setShowVideoModal(false)}
                show={showVideoModal}
                src={options.video_url}
              />
            </>
          )}
        </div>
      </section>
    </div>
  );
}

New.defaultProps = {account: null};
New.propTypes = {
  account: PropTypes.shape({id: PropTypes.number}),
  createNotification: PropTypes.func.isRequired,
  i18n: PropTypes.instanceOf(I18n).isRequired,
  notifications: PropTypes.arrayOf(
    PropTypes.shape({id: PropTypes.string.isRequired}).isRequired
  ).isRequired,
  organization: PropTypes.shape({
    background: PropTypes.shape({url: PropTypes.string.isRequired}).isRequired,
    darken_background: PropTypes.bool.isRequired,
    options: PropTypes.shape({
      capture_email: PropTypes.bool.isRequired,
      capture_external_id: PropTypes.bool.isRequired,
      capture_password: PropTypes.bool.isRequired,
      registration_text: PropTypes.string,
      shibboleth: PropTypes.string,
      show_admin_login: PropTypes.bool.isRequired,
      show_registration_video: PropTypes.bool.isRequired,
      video_url: PropTypes.string
    }).isRequired,
    subdomain: PropTypes.string.isRequired
  }).isRequired,
  session: PropTypes.shape({
    redirect: PropTypes.string,
    shibboleth: PropTypes.string.isRequired
  }).isRequired
};

export default New;
