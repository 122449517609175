import PropTypes from "prop-types";
import {useAssessment} from "traitify-widgets";
import DangerousHTML from "legacy/components/partials/dangerous-html";
import Markdown from "legacy/components/partials/markdown";
import useI18n from "legacy/lib/hooks/use-i18n";
import ash from "public/assets/images/survey/ash.jpg";

const instructionsKey = (deckID) => {
  switch(deckID) {
    case "career-deck":
    case "south-east-grocers":
      return "instructions_text";
    case "financial-risk-tolerance-2.0":
      return "financial_instructions_text";
    default:
      return "non_career_instructions_text";
  }
};

function Instructions({animated, nextStep}) {
  const assessment = useAssessment();
  const i18n = useI18n();
  const instructions = () => {
    if(assessment.instructions && assessment.instructions.instructional_text) {
      return <Markdown>{assessment.instructions.instructional_text}</Markdown>;
    }

    if(assessment.instructions && assessment.instructions.instructional_html) {
      return <DangerousHTML html={assessment.instructions.instructional_html} />;
    }

    if(animated) {
      return (
        <div className="animated-instructions">
          <DangerousHTML html={i18n.t("assessment.animated_instruction_text_1")} />
          <ul>
            <div className="ash-visual">
              <img className="ash-image" src={ash} alt="Ash" />
              <div>
                <div className="ash-greeting">{i18n.t("assessment.ash_greeting")}</div>
              </div>
            </div>
          </ul>
          <DangerousHTML html={i18n.t("assessment.animated_instruction_text_2")} />
        </div>
      );
    }

    return <DangerousHTML html={i18n.t(`assessment.${instructionsKey(assessment.deck_id)}`)} />;
  };

  return (
    <div>
      <section className="modal__body__text">
        {instructions()}
      </section>
      <section className="modal__options">
        <button className="m-buffer-sm p-btn-blue" onClick={nextStep} title={i18n.t("assessment.close")} type="button">{i18n.t("assessment.lets_go")}</button>
      </section>
    </div>
  );
}

Instructions.propTypes = {
  animated: PropTypes.bool.isRequired,
  nextStep: PropTypes.func.isRequired
};

export default Instructions;
