import {connect} from "react-redux";
import I18n from "common/lib/i18n";
import {resendCode} from "legacy/constants/sessions/resend-code";
import {verifyCode} from "legacy/constants/sessions/verify-code";
import Component from "./presenter";

const mapStateToProps = ({
  current: {organization}, locale, sessions
}) => ({
  i18n: new I18n(locale, organization),
  sessions
});

export default connect(
  mapStateToProps,
  {resendCode, verifyCode}
)(Component);
