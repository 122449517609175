import {combineEpics, ofType} from "redux-observable";
import {map, filter} from "rxjs/operators";
import {
  LOAD_APP,
  STORE_APP,
  loadDependencies,
  requestApp
} from "legacy/constants/app";
import dependenciesEpics from "./dependencies";
import requestEpics from "./request";

const appEpic = (action$, state$) => action$.pipe(
  ofType(LOAD_APP),
  filter(() => !state$.value.app.loading),
  map(() => (
    state$.value.app.loaded
      ? loadDependencies()
      : requestApp()
  ))
);

const storeAppEpic = (action$) => action$.pipe(
  ofType(STORE_APP),
  map(loadDependencies)
);

export default combineEpics(
  appEpic,
  dependenciesEpics,
  requestEpics,
  storeAppEpic
);
