import {combineEpics, ofType} from "redux-observable";
import {from, of} from "rxjs";
import {catchError, map, mergeMap} from "rxjs/operators";
import {createNotification} from "legacy/constants/notifications";
import axios from "legacy/lib/axios";
import {
  REQUEST_APP,
  REQUEST_APP_FAILED,
  REQUEST_APP_FULFILLED,
  REQUEST_APP_REJECTED,
  REQUEST_APP_SUCCEEDED,
  requestAppFailed,
  requestAppFulfilled,
  requestAppRejected,
  requestAppSucceeded,
  storeApp
} from "legacy/constants/app";

const requestAppEpic = (action$) => action$.pipe(
  ofType(REQUEST_APP),
  mergeMap(({params}) => from(axios.get("/api/user/app", params)).pipe(
    map(requestAppFulfilled),
    catchError((error) => of(requestAppRejected(error.message)))
  ))
);

const requestAppFailedEpic = (action$) => action$.pipe(
  ofType(REQUEST_APP_FAILED),
  map(({error}) => createNotification({kind: "error", message: error}))
);

const requestAppFulfilledEpic = (action$) => action$.pipe(
  ofType(REQUEST_APP_FULFILLED),
  map(({response: {data}}) => (
    data.error
      ? requestAppFailed(data.error)
      : requestAppSucceeded(data)
  ))
);

const requestAppRejectedEpic = (action$) => action$.pipe(
  ofType(REQUEST_APP_REJECTED),
  map(({error}) => error),
  map(requestAppFailed)
);

const requestAppSucceededEpic = (action$) => action$.pipe(
  ofType(REQUEST_APP_SUCCEEDED),
  map(({app}) => app),
  map(storeApp)
);

export default combineEpics(
  requestAppEpic,
  requestAppFailedEpic,
  requestAppFulfilledEpic,
  requestAppRejectedEpic,
  requestAppSucceededEpic
);
