import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import I18n from "common/lib/i18n";
import {Modal} from "legacy/components/partials";

function ForgotPasswordModal({
  createPasswordReset,
  email: defaultEmail,
  i18n,
  onClose,
  organization,
  sessions: {forgotPassword},
  show
}) {
  const [email, setEmail] = useState(defaultEmail || "");
  const onChange = ({target: {value}}) => setEmail(value);
  const onSubmit = (e) => {
    e.preventDefault();

    createPasswordReset({account: {email}});
  };
  const preventDefault = (e) => e.preventDefault();

  useEffect(() => { setEmail(defaultEmail || ""); }, [defaultEmail]);
  useEffect(() => { if(forgotPassword.success) { onClose(); } }, [forgotPassword.success]);

  return (
    <Modal className="p-modal" onClose={onClose} paradox={true} show={show} title={i18n.t("forgot_password.heading")}>
      {organization.options.capture_email ? (
        <form className="p-form-default" onSubmit={onSubmit}>
          <ul>
            <section className="modal__inputs">
              <li>
                <label className="label" htmlFor="email">{i18n.t("attributes.email")}</label>
                <input className="p-form-field-lg" name="email" onChange={onChange} placeholder={i18n.t("attributes.example_email")} type="text" value={email} />
              </li>
              {Object.keys(forgotPassword.errors || {}).map((field) => (
                <li key={field} className="error">
                  {i18n.t(`attributes.${field}`)} {forgotPassword.errors[field].join(", ")}
                </li>
              ))}
              <li className="form__disclaimer text-left">
                <p>{i18n.t("sessions.reset_password_instructions")}</p>
              </li>
            </section>
            <section className="modal__options">
              <button className="m-buffer-sm p-btn-blue" type="submit">{i18n.t("submit")}</button>
            </section>
          </ul>
        </form>
      ) : (
        <form className="p-form-default" onSubmit={preventDefault}>
          <ul>
            <li>
              <label className="label" htmlFor="email">{i18n.t("attributes.email")}</label>
              <div className="form-icon">
                <i className="fa fa-envelope-o" />
              </div>
              <input className="form-field" name="email" onChange={preventDefault} type="text" value={organization.contact_emails} />
            </li>
            <li className="form__disclaimer text-left">
              <p>{i18n.t("sessions.reset_password_instructions_for_external_id")}</p>
            </li>
          </ul>
        </form>
      )}
    </Modal>
  );
}

ForgotPasswordModal.defaultProps = {email: null};
ForgotPasswordModal.propTypes = {
  createPasswordReset: PropTypes.func.isRequired,
  email: PropTypes.string,
  i18n: PropTypes.instanceOf(I18n).isRequired,
  onClose: PropTypes.func.isRequired,
  organization: PropTypes.shape({
    contact_emails: PropTypes.string.isRequired,
    options: PropTypes.shape({
      capture_email: PropTypes.bool.isRequired
    }).isRequired
  }).isRequired,
  sessions: PropTypes.shape({
    forgotPassword: PropTypes.shape({
      errors: PropTypes.shape({email: PropTypes.arrayOf(PropTypes.string.isRequired)}),
      success: PropTypes.bool
    }).isRequired
  }).isRequired,
  show: PropTypes.bool.isRequired
};

export default ForgotPasswordModal;
