import PropTypes from "prop-types";
import closeIcon from "public/assets/images/modal/close.png";

function Modal({children, className, onClose, show, size, title, paradox, ...props}) {
  const classNames = [
    className,
    "modal",
    `modal--${size}`,
    show && "modal--show"
  ].filter(Boolean);

  return (
    <div className={classNames.join(" ")} role="dialog" {...props}>
      <section className={`box ${`modal__container__${size}`}`}>
        <button aria-label="Close" className="close" onClick={onClose} type="button">
          {paradox ? (
            <img alt="close" src={closeIcon} />
          ) : (
            <i className="fa fa-times" />
          )}
        </button>
        {title && (
          <div className="box__title modal__title">
            <h2>{title}</h2>
          </div>
        )}
        <div className="box__content modal__content">
          {children}
        </div>
      </section>
    </div>
  );
}

Modal.defaultProps = {className: null, paradox: false, size: "xlarge", title: null};
Modal.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  paradox: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  size: PropTypes.string,
  title: PropTypes.string
};

export default Modal;
