import {unique} from "common/lib/ext/array";
import {
  ADD_DEPENDENCIES,
  DEPENDENCY_ERROR,
  DEPENDENCY_LOADED,
  DEPENDENCY_LOADING
} from "legacy/constants/app";

export default (
  state = {},
  action
) => {
  switch(action.type) {
    case ADD_DEPENDENCIES:
      return {
        ...state,
        load: unique(state.load.concat(action.dependencies))
      };
    case DEPENDENCY_ERROR:
      return {
        ...state,
        loaded: state.loaded.filter((dependency) => dependency !== action.dependency),
        loading: state.loading.filter((dependency) => dependency !== action.dependency)
      };
    case DEPENDENCY_LOADED:
      return {
        ...state,
        loaded: state.loaded.concat(action.dependency),
        loading: state.loading.filter((dependency) => dependency !== action.dependency)
      };
    case DEPENDENCY_LOADING:
      return {
        ...state,
        loaded: state.loaded.filter((dependency) => dependency !== action.dependency),
        loading: state.loaded.concat(action.dependency)
      };
    default:
      return {
        load: [],
        loaded: [],
        loading: [],
        ...state
      };
  }
};
