import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import I18n from "common/lib/i18n";
import Picker from "legacy/components/partials/assessments/picker";
import DangerousHTML from "legacy/components/partials/dangerous-html";
import Markdown from "legacy/components/partials/markdown";
import useDidMount from "legacy/lib/hooks/use-did-mount";
import icon from "public/assets/images/results/science.svg";

function Thanks({assessmentID}) {
  const locale = useSelector((state) => state.locale);
  const organization = useSelector((state) => state.current.organization);
  const i18n = new I18n(locale, organization);
  const {options} = organization;

  useDidMount(() => {
    if(!options.show_results && options.auto_close_assessment) {
      window.close();
    }
  });

  const text = i18n.tOption("no_results_text");

  return (
    <div className="thanks-results">
      {assessmentID && <Picker />}
      <section>
        <p>
          <img src={icon} width="280" alt="Science" />
        </p>
        {text ? (
          <Markdown>{text}</Markdown>
        ) : (
          <div>
            <h2>{i18n.t("results.thanks.sent_successfully")}</h2>
            <DangerousHTML html={i18n.t("results.thanks.learn_more")} />
          </div>
        )}
      </section>
    </div>
  );
}

Thanks.defaultProps = {assessmentID: null};
Thanks.propTypes = {assessmentID: PropTypes.string};

export default Thanks;
