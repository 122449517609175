import {connect} from "react-redux";
import {loadAssessment} from "legacy/constants/assessment";
import {updateCurrentRecommendation, updateCurrentReport} from "legacy/constants/current";
import {storeProfile} from "legacy/constants/profile";
import Component from "./presenter";

const mapStateToProps = ({
  assessment,
  current: {organization, recommendation, report},
  locale,
  profile
}) => ({
  assessment,
  locale,
  organization,
  profile,
  recommendation,
  report
});

export default connect(
  mapStateToProps,
  {loadAssessment, storeProfile, updateCurrentRecommendation, updateCurrentReport}
)(Component);
