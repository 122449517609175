import {createBrowserRouter} from "react-router-dom";
import ErrorBoundary from "common/components/layout/router-error-boundary";
import Container from "legacy/components/layout/container";
import * as Pages from "legacy/components/pages";
import Loader from "legacy/components/partials/loader";

export default function createRouter() {
  return createBrowserRouter(
    [{
      children: [
        {element: <Loader component={Pages.Sessions.New} page={true} />, path: "/"},
        {element: <Loader component={Pages.Personality.ShowAdmin} dependencies={["assessments"]} page={true} />, path: "/admin/personality/:assessmentID/:adminToken"},
        {element: <Loader component={Pages.Assessment.Survey} page={true} />, path: "/assessment/:assessmentID"},
        {element: <Loader component={Pages.Reload} page={true} />, path: "/choose-organization"},
        {element: <Loader component={Pages.Reload} page={true} />, path: "/login"},
        {element: <Loader component={Pages.Passwords.Edit} page={true} />, path: "/passwords/:token/edit"},
        {element: <Loader component={Pages.Personality.Show} dependencies={["assessments"]} page={true} />, path: "/personality"},
        {element: <Loader component={Pages.Personality.Show} dependencies={["assessments"]} page={true} />, path: "/personality/:assessmentID"},
        {element: <Loader component={Pages.Personality.Thanks} page={true} />, path: "/personality/:assessmentID/thanks"},
        {element: <Loader component={Pages.Recommendation.Survey} page={true} />, path: "/recommendation/survey"},
        {element: <Loader component={Pages.Recommendation.Thanks} page={true} />, path: "/recommendation/thanks"},
        {element: <Loader component={Pages.Sessions.New} page={true} />, path: "/sessions/new"},
        {element: <Loader component={Pages.Settings} page={true} />, path: "/settings"},
        {element: <Loader component={Pages.Careers} dependencies={["assessments"]} page={true} />, path: "/careers"},
        {element: <Loader component={Pages.Careers} dependencies={["assessments"]} page={true} />, path: "/careers/:assessmentID"},
        {Component: Pages.NotFound}
      ],
      Component: Container,
      ErrorBoundary
    }]
  );
}
