import PropTypes from "prop-types";
import {Component} from "react";
import {displayName} from "legacy/constants/current";

export default class OriginalAccount extends Component {
  static propTypes = {
    original: PropTypes.shape({
      account: PropTypes.shape({name: PropTypes.string}),
      user: PropTypes.shape({name: PropTypes.string})
    }).isRequired
  };
  render() {
    const {original: {account, user}} = this.props;
    if(!account) { return <div />; }

    return (
      <div className="alert alert--success">
        <p>
          Return to being <a href="/spoofs" data-method="delete">{displayName({account, user})}</a>
        </p>
      </div>
    );
  }
}
