import {connect} from "react-redux";
import Component from "./presenter";

const mapStateToProps = ({
  assessment, current: {organization}
}) => ({
  assessment, organization
});

export default connect(mapStateToProps)(Component);
