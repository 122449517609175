export const CREATE_SESSION = "CREATE_SESSION";
export const CREATE_SESSION_2FA = "CREATE_SESSION_2FA";
export const CREATE_SESSION_FAILED = "CREATE_SESSION_FAILED";
export const CREATE_SESSION_FULFILLED = "CREATE_SESSION_FULFILLED";
export const CREATE_SESSION_MESSAGE = "CREATE_SESSION_MESSAGE";
export const CREATE_SESSION_REJECTED = "CREATE_SESSION_REJECTED";
export const CREATE_SESSION_SUCCEEDED = "CREATE_SESSION_SUCCEEDED";

export const createSession = (params) => ({type: CREATE_SESSION, params});
export const createSession2fa = () => ({type: CREATE_SESSION_2FA});
export const createSessionFailed = (error) => ({type: CREATE_SESSION_FAILED, error});
export const createSessionFulfilled = (response) => ({type: CREATE_SESSION_FULFILLED, response});
export const createSessionMessage = (message) => ({type: CREATE_SESSION_MESSAGE, message});
export const createSessionRejected = (error) => ({type: CREATE_SESSION_REJECTED, error});
export const createSessionSucceeded = (data) => ({type: CREATE_SESSION_SUCCEEDED, data});
