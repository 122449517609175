import {combineEpics, ofType} from "redux-observable";
import {from, of} from "rxjs";
import {catchError, filter, map, mergeMap} from "rxjs/operators";
import {i18nAxios} from "legacy/lib/axios";
import {createNotification} from "legacy/constants/notifications";
import {
  RESEND_CODE,
  RESEND_CODE_FAILED,
  RESEND_CODE_FULFILLED,
  RESEND_CODE_REJECTED,
  resendCodeFailed,
  resendCodeFulfilled,
  resendCodeRejected,
  resendCodeSucceeded
} from "legacy/constants/sessions/resend-code";

const resendCodeEpic = (action$, state$) => action$.pipe(
  ofType(RESEND_CODE),
  mergeMap(() => from(
    i18nAxios(state$.value.locale.code).post("/api/user/session/resend_code")
  ).pipe(
    map(resendCodeFulfilled),
    catchError((error) => of(resendCodeRejected(error.message)))
  ))
);

const resendCodeFailedEpic = (action$) => action$.pipe(
  ofType(RESEND_CODE_FAILED),
  filter(() => window.location.pathname === "/login"),
  map(({error}) => createNotification({kind: "error", message: error}))
);

const resendCodeFulfilledEpic = (action$) => action$.pipe(
  ofType(RESEND_CODE_FULFILLED),
  map(({response: {data}}) => (
    data.success
      ? resendCodeSucceeded(data)
      : resendCodeFailed(data.errors)
  ))
);

const resendCodeRejectedEpic = (action$) => action$.pipe(
  ofType(RESEND_CODE_REJECTED),
  map(({error}) => error),
  map(resendCodeFailed)
);

export default combineEpics(
  resendCodeEpic,
  resendCodeFailedEpic,
  resendCodeFulfilledEpic,
  resendCodeRejectedEpic
);
