import {connect} from "react-redux";
import I18n from "common/lib/i18n";
import Component from "./presenter";

const mapStateToProps = ({
  current: {organization}, locale, profile
}) => ({
  i18n: new I18n(locale, organization), locale, organization, profile
});

export default connect(mapStateToProps)(Component);
