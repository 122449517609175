import PropTypes from "prop-types";
import I18n from "common/lib/i18n";
import {displayInitials} from "legacy/constants/current";

function Links({assessments, current: {organization, user}, i18n, mobile}) {
  const assessment = assessments && assessments.find((a) => a.completed_at);
  const {options} = organization;
  const itemClass = `nav-item ${mobile ? "md-hidden" : ""}`;

  return (
    <>
      {user && (
        <>
          {user.admin && (
            <div className={itemClass}>
              <a className="nav-link" href="/admin">{i18n.t("navbar.admin_portal")}</a>
            </div>
          )}
          {options.show_results && assessment && (
            <div className={itemClass}>
              <a className="nav-link" href="/personality">{i18n.t("navbar.personality")}</a>
            </div>
          )}
          {options.show_settings_in_dropdown && (
            <div className={itemClass}>
              <a className="nav-link" href="/settings">{i18n.t("navbar.settings")}</a>
            </div>
          )}
          {options.show_contact_us_in_dropdown && (
            <div className={itemClass}>
              <a className="nav-link" href={`mailto:${organization.contact_emails}`} title="Contact Support">
                <i className="fa fa-life-ring" /> {i18n.t("navbar.contact")}
              </a>
            </div>
          )}
        </>
      )}
      <div className={itemClass}>
        <a className="nav-link" href="/sessions" data-method="delete">{i18n.t("logout")}</a>
      </div>
    </>
  );
}

Links.defaultProps = {mobile: false};
Links.propTypes = {
  assessments: PropTypes.arrayOf(
    PropTypes.shape({completed_at: PropTypes.number}).isRequired
  ).isRequired,
  current: PropTypes.shape({
    organization: PropTypes.shape({
      contact_emails: PropTypes.string.isRequired,
      options: PropTypes.shape({
        show_contact_us_in_dropdown: PropTypes.bool,
        show_results: PropTypes.bool,
        show_settings_in_dropdown: PropTypes.bool
      }).isRequired
    }).isRequired,
    user: PropTypes.shape({admin: PropTypes.bool.isRequired})
  }).isRequired,
  i18n: PropTypes.instanceOf(I18n).isRequired,
  mobile: PropTypes.bool
};

function AccountLinks({isSessions, ...props}) {
  const {account, profile} = props.current;

  return (
    <>
      <div className="nav-item nav-hover">
        <button className="nav-toggle hidden md-flex" onClick={() => {}} type="button">
          {(account || !isSessions) && (
            <>{displayInitials({account, profile}) || <i className="fa fa-user fa-lg" />} <i className="fa fa-angle-down fa-lg ml-1" /></>
          )}
        </button>
        <div className="nav-dropdown">
          <Links {...props} />
        </div>
      </div>
      <Links {...props} mobile={true} />
    </>
  );
}

AccountLinks.propTypes = {
  current: PropTypes.shape({
    account: PropTypes.shape({name: PropTypes.string}),
    profile: PropTypes.shape({name: PropTypes.string})
  }).isRequired,
  isSessions: PropTypes.bool.isRequired
};

export default AccountLinks;
