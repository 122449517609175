export const CREATE_PASSWORD_RESET = "CREATE_PASSWORD_RESET";
export const CREATE_PASSWORD_RESET_FAILED = "CREATE_PASSWORD_RESET_FAILED";
export const CREATE_PASSWORD_RESET_FULFILLED = "CREATE_PASSWORD_RESET_FULFILLED";
export const CREATE_PASSWORD_RESET_REJECTED = "CREATE_PASSWORD_RESET_REJECTED";
export const CREATE_PASSWORD_RESET_SUCCEEDED = "CREATE_PASSWORD_RESET_SUCCEEDED";
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const UPDATE_PASSWORD_FAILED = "CREATE_PASSWORD_FAILED";
export const UPDATE_PASSWORD_FULFILLED = "CREATE_PASSWORD_FULFILLED";
export const UPDATE_PASSWORD_REJECTED = "CREATE_PASSWORD_REJECTED";
export const UPDATE_PASSWORD_SUCCEEDED = "CREATE_PASSWORD_SUCCEEDED";

export const createPasswordReset = (params) => ({type: CREATE_PASSWORD_RESET, params});
export const createPasswordResetFailed = (errors) => ({
  type: CREATE_PASSWORD_RESET_FAILED, errors
});
export const createPasswordResetFulfilled = (response) => ({
  type: CREATE_PASSWORD_RESET_FULFILLED, response
});
export const createPasswordResetRejected = (errors) => ({
  type: CREATE_PASSWORD_RESET_REJECTED, errors
});
export const createPasswordResetSucceeded = (message) => ({
  type: CREATE_PASSWORD_RESET_SUCCEEDED, message
});
export const updatePassword = (params) => ({type: UPDATE_PASSWORD, params});
export const updatePasswordFailed = (errors) => ({type: UPDATE_PASSWORD_FAILED, errors});
export const updatePasswordFulfilled = (response) => ({type: UPDATE_PASSWORD_FULFILLED, response});
export const updatePasswordRejected = (errors) => ({type: UPDATE_PASSWORD_REJECTED, errors});
export const updatePasswordSucceeded = ({redirect}) => ({
  type: UPDATE_PASSWORD_SUCCEEDED, redirect
});
