import {connect} from "react-redux";
import I18n from "common/lib/i18n";
import {Loader} from "legacy/components/partials";
import Component from "./presenter";

const mapStateToProps = ({
  assessment, current: {organization}, locale
}) => ({
  assessment, i18n: new I18n(locale, organization), organization
});
const component = connect(mapStateToProps)(Component);

export default (props) => (
  <Loader component={component} componentProps={props} dependencies={["assessment"]} />
);
