import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {CenterModal} from "legacy/components/partials";

function VideoModal({onClose, show, src}) {
  const [videoSrc, setVideoSrc] = useState(src);

  useEffect(() => {
    const newSrc = new URL(src);
    newSrc.searchParams.set("autoplay", 1);
    setVideoSrc(newSrc.href);
  }, []);

  return (
    <CenterModal className="p-modal" onClose={onClose} paradox={true} show={show}>
      <div style={{height: "100%", width: "100%"}}>
        {show && (
          <iframe
            width="100%"
            height="100%"
            src={videoSrc}
            title="Organization Video Player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen={true}
          />
        )}
      </div>
    </CenterModal>
  );
}

VideoModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  organization: PropTypes.shape({
    options: PropTypes.shape({
      capture_email: PropTypes.bool.isRequired,
      capture_external_id: PropTypes.bool.isRequired,
      capture_password: PropTypes.bool.isRequired
    }).isRequired,
    subdomain: PropTypes.string.isRequired
  }).isRequired,
  sessions: PropTypes.shape({
    error: PropTypes.string,
    require2fa: PropTypes.bool.isRequired
  }).isRequired,
  show: PropTypes.bool.isRequired,
  src: PropTypes.string.isRequired
};

export default VideoModal;
