import PropTypes from "prop-types";
import {Components} from "traitify-widgets";
import {mutable} from "common/lib/ext/object";
import {Assessments} from "legacy/components/partials";
import AllowRetake from "../allow-retake";
import Heading from "../heading";

function TypeResults({assessment, mine, organization, widgetProps: _widgetProps}) {
  const showCareers = assessment.deck_id === "career-deck" && organization.options.show_careers !== "None";
  const careersLink = showCareers && `/careers/${assessment.id}`;

  const widgetProps = mutable(_widgetProps);
  widgetProps.options.careersLink = careersLink;
  widgetProps.options.showHeaders = true;

  return (
    <div className="show-results type-results">
      <Components.Container {...widgetProps}>
        <Assessments.Picker />
        <Heading />
        <Components.Results.Container />
        {mine && <AllowRetake />}
      </Components.Container>
    </div>
  );
}

TypeResults.propTypes = {
  assessment: PropTypes.shape({
    deck_id: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired
  }).isRequired,
  mine: PropTypes.bool.isRequired,
  organization: PropTypes.shape({
    options: PropTypes.shape({
      show_careers: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  widgetProps: PropTypes.shape({
    assessmentID: PropTypes.string.isRequired
  }).isRequired
};

export default TypeResults;
