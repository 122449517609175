import PropTypes from "prop-types";
import {Children, Component, createElement} from "react";
import {Loading} from "legacy/components/partials";

export default class Loader extends Component {
  static propTypes = {
    addDependencies: PropTypes.func.isRequired,
    app: PropTypes.shape({
      dependencies: PropTypes.shape({
        load: PropTypes.arrayOf(PropTypes.string),
        loaded: PropTypes.arrayOf(PropTypes.string)
      }),
      loaded: PropTypes.bool,
      loading: PropTypes.bool
    }).isRequired,
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    componentProps: PropTypes.shape({}),
    dependencies: PropTypes.arrayOf(PropTypes.string),
    page: PropTypes.bool,
    render: PropTypes.func
  };
  static defaultProps = {
    component: null,
    componentProps: {},
    children: null,
    dependencies: [],
    page: false,
    render: null
  };
  componentDidMount() {
    this.props.addDependencies(this.props.dependencies);
  }
  render() {
    const {
      app,
      component,
      componentProps,
      children,
      dependencies,
      page,
      render
    } = this.props;

    if(!app.loaded) { return null; }
    if(dependencies.find((dependency) => (
      !app.dependencies.loaded.find((d) => d === dependency)
    ))) { return page ? <Loading /> : null; }
    if(component) { return createElement(component, componentProps); }
    if(render) { return render(componentProps); }
    if(!children) { return null; }
    return typeof children === "function"
      ? children(componentProps)
      : Children.only(children);
  }
}
