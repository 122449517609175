import {connect} from "react-redux";
import I18n from "common/lib/i18n";
import {updateLocale} from "legacy/constants/locale";
import Component from "./presenter";

const mapStateToProps = ({current: {organization}, locale}) => (
  {i18n: new I18n(locale, organization), locale, organization}
);

export default connect(
  mapStateToProps,
  {updateLocale}
)(Component);
