import {connect} from "react-redux";
import {Loader} from "legacy/components/partials";
import Component from "./presenter";

const mapStateToProps = ({assessment}) => ({assessment});

export const ConnectedComponent = connect(mapStateToProps)(Component);

export default (props) => (
  <Loader component={ConnectedComponent} componentProps={props} dependencies={["assessment"]} />
);
