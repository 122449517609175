import {connect} from "react-redux";
import I18n from "common/lib/i18n";
import {updateCurrent} from "legacy/constants/current";
import {assignLocation} from "legacy/constants/history";
import {createNotification} from "legacy/constants/notifications";
import Component from "./presenter";

const mapStateToProps = ({
  current, locale
}) => ({
  current, i18n: new I18n(locale, current.organization), locale
});

export default connect(
  mapStateToProps,
  {assignLocation, createNotification, updateCurrent}
)(Component);
