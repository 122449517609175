export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const CREATE_NOTIFICATION = "CREATE_NOTIFICATION";
export const CREATE_NOTIFICATION_FROM_OBJECT = "CREATE_NOTIFICATION_FROM_OBJECT";
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";

export const addNotification = (notification) => ({type: ADD_NOTIFICATION, notification});
export const createNotification = (notification) => ({type: CREATE_NOTIFICATION, notification});
export const createNotificationFromObject = (notification) => ({
  type: CREATE_NOTIFICATION_FROM_OBJECT, notification
});
export const deleteNotification = (id) => ({type: DELETE_NOTIFICATION, id});
