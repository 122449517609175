import {STORE_APP} from "legacy/constants/app";
import {
  UPDATE_CURRENT,
  UPDATE_CURRENT_ACCOUNT,
  UPDATE_CURRENT_ORGANIZATION,
  UPDATE_CURRENT_PROFILE,
  UPDATE_CURRENT_RECOMMENDATION,
  UPDATE_CURRENT_REPORT,
  UPDATE_CURRENT_USER
} from "legacy/constants/current";

export default (
  state = {},
  action
) => {
  switch(action.type) {
    case STORE_APP:
      return {
        ...state,
        ...action.app.current
      };
    case UPDATE_CURRENT:
      return {
        ...state,
        ...action.current
      };
    case UPDATE_CURRENT_ACCOUNT:
      return {
        ...state,
        account: action.account
      };
    case UPDATE_CURRENT_ORGANIZATION:
      return {
        ...state,
        organization: action.organization
      };
    case UPDATE_CURRENT_PROFILE:
      return {
        ...state,
        profile: action.profile
      };
    case UPDATE_CURRENT_RECOMMENDATION:
      return {
        ...state,
        recommendation: action.recommendation
      };
    case UPDATE_CURRENT_REPORT:
      return {
        ...state,
        report: action.report
      };
    case UPDATE_CURRENT_USER:
      return {
        ...state,
        user: action.user
      };
    default:
      return state;
  }
};
