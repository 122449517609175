import {STORE_APP} from "legacy/constants/app";
import {
  ADD_NOTIFICATION,
  DELETE_NOTIFICATION
} from "legacy/constants/notifications";

export default (
  state = [],
  action
) => {
  switch(action.type) {
    case STORE_APP:
      return [
        ...state,
        ...action.app.notifications
      ];
    case ADD_NOTIFICATION:
      return [...state, action.notification];
    case DELETE_NOTIFICATION:
      return state.filter((notification) => notification.id !== action.id);
    default:
      return state;
  }
};
