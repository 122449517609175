import PropTypes from "prop-types";
import {useEffect, useState, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import Locale from "common/lib/locale";
import {updateLocale} from "legacy/constants/locale";
import {createNotification} from "legacy/constants/notifications";
import Axios, {i18nAxios} from "legacy/lib/axios";
import useI18n from "legacy/lib/hooks/use-i18n";

function SelectLocale({closeModal, nextStep}) {
  const {account, organization, profile, user} = useSelector((store) => store.current);
  const dispatch = useDispatch();
  const i18n = useI18n();
  const locale = useSelector((store) => store.locale);
  const request = useRef(null);
  const [originalLocale] = useState(() => {
    let userLocale;

    if(organization.locales.length > 1) {
      let code = profile.primary_language;
      if(!code) { code = account.locale; }
      if(code) { userLocale = Locale.fromUser(code); }
      if(userLocale && !organization.locales.find((l) => l.code === userLocale.code)) {
        userLocale = null;
      }
    } else {
      userLocale = organization.default_locale;
    }

    return userLocale;
  });

  useEffect(() => {
    dispatch(updateLocale(originalLocale || organization.default_locale));

    if(originalLocale) { nextStep(); }
  }, [originalLocale]);

  useEffect(() => (() => request.current && request.current.cancel()), []);

  const onLocaleChange = ({target: {value}}) => {
    const newLocale = organization.locales.find(({code}) => code === value);

    newLocale && dispatch(updateLocale(newLocale));
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const axios = i18nAxios(locale.code);
    const params = {
      account: {
        locale: locale.code,
        users_attributes: [{
          id: user.id,
          profile_attributes: {
            primary_language: locale.code
          }
        }]
      }
    };
    request.current = Axios.CancelToken.source();

    axios.put("/api/user/account", params, {
      cancelToken: request.current.token
    }).then(({data: {errors, success}}) => {
      request.current = null;

      if(success) {
        nextStep();
      } else {
        dispatch(createNotification({kind: "error", message: errors}));
        closeModal();
      }
    });
  };

  return (
    <form className="p-form-default" onSubmit={onSubmit}>
      <section className="modal__body__text">
        <label htmlFor="assessment-locale">{i18n.t("assessment.preferred_language")}</label>
        <select className="p-form-field" id="assessment-locale" onChange={onLocaleChange} value={locale.code}>
          {organization.locales.map(({code, name}) => (
            <option key={code} value={code}>{name}</option>
          ))}
        </select>
      </section>
      <section className="modal__options">
        <button className="m-buffer-sm p-btn-blue" title={i18n.t("assessment.close")} type="submit">{i18n.t("submit")}</button>
      </section>
    </form>
  );
}

SelectLocale.propTypes = {
  closeModal: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired
};

export default SelectLocale;
