import {connect} from "react-redux";
import I18n from "common/lib/i18n";
import {Loader} from "legacy/components/partials";
import {loadAssessment} from "legacy/constants/assessment";
import {assignLocation} from "legacy/constants/history";
import Component from "./presenter";

const mapStateToProps = ({assessment, current, locale}) => ({
  assessment, current, i18n: new I18n(locale, current.organization), locale
});
const component = connect(
  mapStateToProps,
  {assignLocation, loadAssessment}
)(Component);

export default () => (
  <Loader component={component} dependencies={["assessment", "assessments"]} />
);
