import PropTypes from "prop-types";
import {Component} from "react";
import I18n from "common/lib/i18n";

export default class Field extends Component {
  static propTypes = {
    children: PropTypes.node,
    i18n: PropTypes.instanceOf(I18n).isRequired,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    namespace: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    showLabel: PropTypes.bool,
    type: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  };
  static defaultProps = {
    children: null,
    label: null,
    namespace: null,
    placeholder: null,
    showLabel: false,
    type: "text",
    value: null
  };
  render() {
    const {
      children,
      i18n,
      label: _label,
      name,
      namespace,
      placeholder,
      showLabel,
      type,
      value,
      ..._props
    } = this.props;
    const id = namespace ? [namespace, name].join("-") : name;
    const label = _label || i18n.t(`attributes.${name}`);
    const props = {
      className: "p-form-field",
      id,
      ..._props,
      name,
      value: value || ""
    };

    return (
      <>
        {showLabel && <label key="label" className="label" htmlFor={id}>{label}</label>}
        {type === "select" ? (
          <select key="select" {...props}>
            <option value="">{label}</option>
            {children}
          </select>
        ) : (
          <input key="input" placeholder={placeholder || label} type={type} {...props} />
        )}
      </>
    );
  }
}
