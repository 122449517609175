import PropTypes from "prop-types";
import {date} from "common/lib/ext/number";
import I18n from "common/lib/i18n";
import useToggle from "legacy/lib/hooks/use-toggle";

function AssessmentType({
  assessment,
  assessments,
  assessmentType,
  assignLocation,
  locale,
  i18n,
  cognitiveTest,
  setAssessment
}) {
  const completedAssessments = assessments
    .filter((_assessment) => _assessment.completed_at)
    .filter((_assessment) => _assessment.deck_id === assessmentType.deck_id);

  const [isOpen, toggleIsOpen] = useToggle();

  const typeI18n = assessmentType.i18ns.find((i18ns) => i18ns.locale_code === locale.rails_code);
  const active = assessmentType.deck_id === assessment.deck_id;
  const name = typeI18n ? typeI18n.name : assessmentType.name;

  const showAssessment = (id) => {
    setAssessment(id || completedAssessments[0].id);
  };
  const takeAssessment = () => {
    assignLocation(`/assessment?deck_id=${assessmentType.deck_id}`, true);
  };
  let status = "";
  if(completedAssessments.length > 0) { status += " status--complete"; }
  if(cognitiveTest && cognitiveTest.completed) { status += " status--complete"; }

  if(cognitiveTest) {
    return (
      <li key={assessmentType.deck_id} className={active ? "assessment-active" : ""}>
        <button disabled={cognitiveTest.completed} onClick={cognitiveTest.completed ? () => { toggleIsOpen(); } : takeAssessment} title={name} type="button">
          <div className="assessment-details">
            <i className={`fa ${cognitiveTest.completed ? "fa-check-circle" : "fa-circle"} ${status}`} />
            <div>
              <span>{`${name} - ${cognitiveTest.completed ? ` ${i18n.t("results.completed")}` : ` ${i18n.t("results.incomplete")}`}`}</span>
              <i className="fa fa-solid fa-chevron-down" />
            </div>
          </div>
        </button>
        <div className={`assessment-dropdown ${!isOpen && "hidden"}`}>
          <hr />
          <div className="select-assessment" key={cognitiveTest.id}>
            <button type="button" disabled={cognitiveTest.completed} onClick={() => { showAssessment(cognitiveTest.id); }}>
              {i18n.t("results.completed")} - {date(cognitiveTest.completed_at, {month: "2-digit", day: "2-digit", year: "numeric"})}
            </button>
          </div>
        </div>
      </li>
    );
  }

  return (
    <li key={assessmentType.deck_id} className={active ? "assessment-active" : ""}>
      <button onClick={completedAssessments.length > 0 ? () => { toggleIsOpen(); } : takeAssessment} title={name} type="button">
        <div className="assessment-details">
          <i className={`fa ${completedAssessments.length > 0 ? "fa-check-circle" : "fa-circle"} ${status}`} />
          <div>
            <span>{`${name} - ${completedAssessments.length > 0 ? ` ${i18n.t("results.completed")}` : ` ${i18n.t("results.incomplete")}`}`}</span>
            <i className="fa fa-solid fa-chevron-down" />
          </div>
        </div>
      </button>
      <div className={`assessment-dropdown ${!isOpen && "hidden"}`}>
        <hr />
        {completedAssessments.map((_assessment) => (
          <div className="select-assessment" key={_assessment.id}>
            <button type="button" onClick={() => { showAssessment(_assessment.id); }}>
              {i18n.t("results.completed")} - {date(_assessment.completed_at, {month: "2-digit", day: "2-digit", year: "numeric"})}
            </button>
          </div>
        ))}
      </div>
    </li>
  );
}

AssessmentType.defaultProps = {cognitiveTest: null};
AssessmentType.propTypes = {
  assessment: PropTypes.shape({deck_id: PropTypes.string}).isRequired,
  assessments: PropTypes.arrayOf(
    PropTypes.shape({
      completed_at: PropTypes.number,
      deck_id: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  assessmentType: PropTypes.shape({
    color: PropTypes.string.isRequired,
    deck_id: PropTypes.string.isRequired,
    i18ns: PropTypes.arrayOf(
      PropTypes.shape({
        locale_code: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
      })
    ).isRequired,
    icon: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  assignLocation: PropTypes.func.isRequired,
  cognitiveTest: PropTypes.shape({
    completed: PropTypes.bool,
    id: PropTypes.string,
    completed_at: PropTypes.number
  }),
  locale: PropTypes.shape({rails_code: PropTypes.string}).isRequired,
  i18n: PropTypes.instanceOf(I18n).isRequired,
  setAssessment: PropTypes.func.isRequired
};

export default AssessmentType;
